@import "../../../assets/sass/var";

.thead {
  .th {
    padding: 30px 0;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #737484;
    border: solid $background-border-table;
    border-width: 1px 0;

    .sort-title {
      cursor: pointer;
    }

    &-name {
      font-weight: bold;
      color: $btn-background;
      cursor: pointer;

      &-light {
        font-weight: 400;
        color: #737484;
      }
    }

    &-name-long {
      font-weight: bold;
      color: $btn-background;
      cursor: pointer;
      display: inline-block;
      width: 140px;

      &-light {
        font-weight: 400;
        color: #737484;
        display: inline-block;
        width: 100px;
      }
    }

    .wrap {
      display: inline-block;
      margin-top: -24px;
      margin-left: 10px;
    }

    .wrap-long {
      display: block;
      margin-top: -35px;
      margin-left: 150px;
    }

    .arrow {
      width: 4px;
      height: 4px;
      display: inline-block;
      position: relative;
      top: -10px;
      left: 5px;
    }

    .arrow span {
      top: 8px;
      position: absolute;
      width: 10px;
      height: 1.6px;
      background-color: black;
      display: inline-block;
      transition: all 0.2s ease;
    }

    .arrow span:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }

    .arrow span:last-of-type {
      left: -6px;
      transform: rotate(-45deg);
    }

    .arrow.active span:first-of-type {
      transform: rotate(-45deg);
    }

    .arrow.active span:last-of-type {
      transform: rotate(45deg);
      color: #ae1412;
    }

    .arrow-gray {
      width: 4px;
      height: 4px;
      display: inline-block;
      position: relative;
      top: -10px;
      left: 5px;
    }

    .arrow-gray span {
      top: 8px;
      position: absolute;
      width: 10px;
      height: 1.6px;
      background-color: #737484;
      display: inline-block;
      transition: all 0.2s ease;
    }

    .arrow-gray span:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }

    .arrow-gray span:last-of-type {
      right: 0;
      transform: rotate(-45deg);
    }

    .arrow-gray.active span:first-of-type {
      transform: rotate(-45deg);
    }

    .arrow-gray.active span:last-of-type {
      transform: rotate(45deg);
    }


    .up {
      width: 15px;
      height: 15px;
      margin-left: 12px;
      transition: 0.3s;
      transform: rotate(180deg);
      cursor: pointer;

      &-gray {
        width: 15px;
        height: 15px;
        margin-left: 12px;
        transition: 0.3s;
        transform: rotate(180deg);
        cursor: pointer;

      }
    }

    .down {
      width: 15px;
      height: 15px;
      margin-left: 12px;
      transition: 0.3s;
      transform: rotate(0deg);
      cursor: pointer;

      &-gray {
        width: 15px;
        height: 15px;
        transition: 0.3s;
        transform: rotate(0deg);
        margin-left: 12px;
        cursor: pointer;

      }
    }

    .none {
      display: none;
    }
  }
}