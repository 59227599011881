@import "src/assets/sass/var";

.admin__left-nav {
  display: block;
  position: fixed;
  width: 290px;
  margin-top: 120px;

  .css-1afjkum-control {
    border: 1px solid #D6D8E7;
  }

  .css-1afjkum-control:hover {
    border: 1px solid $btn-background;;
  }

  .css-jbpbp8-Input {
    width: 0;
  }

  .css-18h66qw-control:hover {
    border: 1px solid black;
  }

  .css-18h66qw-control {
    border: 1px solid #D6D8E7;
  }

  .css-1okebmr-indicatorSeparator {
    width: 0;
  }

  .css-134cg2f {
    padding: 2px 27px !important;
    left: -5px !important;
  }

  .css-kws6uf-ValueContainer {
    padding: 2px 31px !important;
    left: -8px !important;
  }

  .css-1y8ltyy {
    width: 0 !important;
  }

  .select {
    width: 233px !important;
    margin-left: 29px !important;
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .list {
    list-style: none;
    padding: 0 30px;
    margin: 0;

    .item {

      .item-link {
        list-style: none;
        line-height: 1;
        padding: 0 0 20px 0;
        margin: 0;
        display: block;
        font-size: 16px;
        color: $paragraph-text-background;
        letter-spacing: 0.25px;
        text-decoration: none;
      }

      .active {
        color: $btn-background;
        text-decoration: underline;
      }
    }

    .item a:hover {
      text-decoration: underline;
    }

    .item a:active {
      font-weight: bold;
    }

    .active {
      font-weight: bold;
    }
  }
}