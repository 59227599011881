@import "../../../assets/sass/var";

.events-window {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: $background-popup-window;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &__alert {
    border-radius: $border-radius-popup;
    background: $main-background;
    min-width: 512px;
    min-height: 300px;
    transition: .5s all;
    position: relative;
    top: 0;
    left: 0;

    .btn-close-block {
      position: relative;
      float: right;
      margin-right: -10px;
      margin-top: -10px;
      z-index: 2;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      max-width: 100%;
      margin: 16px 24px 21px 24px;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
      margin-bottom: 16px;
      font-style: normal;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.3px;
      color: #2A2A30;

      &__rightWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 150px;

        &_edd-button {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #49CD6E;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
          z-index: 2;
        }

        &_edd-button:hover {
          cursor: pointer;
        }

        &_date {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: right;
          letter-spacing: 0.25px;
          color: #737484;
        }
      }

      &__time {
      }
    }

    .grid {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 100%;

      .line-grid {
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: left;

        border-top: 1px solid $border-table-time-line;

        &:nth-last-child(-n+1) {
          border-bottom: 1px solid $border-table-time-line;
          margin-bottom: 20px;
        }

        .time_title {
          margin-left: 27px;
        }
      }
    }

    .events-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: start;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
      padding-left: 100px;
      box-sizing: border-box;
      z-index: 1;

      .day-event {
        border-left: 2px solid black;
        padding: 5px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        font-size: 12px;
        margin-bottom: 10px;
        margin-right: 10px;
        z-index: 10;
        overflow: hidden;
        position: relative;
        width: 180px;

        .reason {
          font-weight: bold;
          display: block;
        }

        .start {
          display: block;
          font-weight: normal;

          .waiting-room {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;
            border-bottom: 1px solid $paragraph-text-background;
          }

          .status {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;
          }

          .waiting-room {
            margin-left: 10px;
            width: 28px;
            height: 28px;
            margin-top: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;
            overflow: hidden;

            .img {
              width: 28px;
              height: 28px;
              border-radius: 50%;

            }
          }

          .waiting-room:hover {
            cursor: pointer;
            border-bottom: 2px solid #49CD6E;
          }

          .img-ok {
            width: 22px;
            height: 22px;
            border: 2px solid #49CD6E;
            border-radius: 15%;
            padding: 3px;
            margin-left: 10px;
          }

          .img-Close {
            width: 22px;
            height: 22px;
            border: 2px solid #fd510dc9;
            border-radius: 15%;
            padding: 3px;
            margin-left: 10px;
          }
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 35px;
          height: 35px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;

          .photo {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
        }
      }

      .day-event:hover {
        cursor: pointer;
      }
    }


    .edd-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 65%;
      left: 48.5%;
      z-index: 100;
      width: 72px;
      height: 32px;
      border-radius: 5%;
      background: #49CD6E;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);

      &_title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #FFFFFF;
      }
    }

    .edd-button:hover {
      cursor: pointer;
    }

    .button {
      margin: 5px;
    }
  }
}
