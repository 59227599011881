@import "../../assets/sass/var";

.checkbox {
  margin-bottom: 20px;
  position: relative;
  min-height: 30px;

  &_btn {
    .checkbox {
      &__input {
        appearance: none;
        position: absolute;
        width: 137px;
        height: 44px;
        z-index: 100;
      }

      &__label {
        font-size: 16px;
        padding: 40px;
        cursor: pointer;
      }

      &__text {
        padding-left: 20px;
        padding-top: 8px;
        position: absolute;
        z-index: 1000;
        color: white;
        user-select: none;
        display: flex;
      }

      &__text1 {
        padding-left: 20px;
        padding-top: 8px;
        position: absolute;
        z-index: 1000;
        color: $paragraph-text-background;
        user-select: none;
        display: flex;
      }

      &__label:before {
        background-color: #EFF0F6;
        width: 137px;
        height: 44px;
        border-radius: 10px;
      }

      &__label:after {
        width: 165px;
        height: 44px;
        border-radius: 10px;
        top: 0;
        left: 0;
      }
    }
  }

  &_color {
    .checkbox {
      &__label:before {
        width: 20px;
        height: 20px;
        border-radius: 0.1px;
        z-index: 0;
      }

      &__label:after {
        width: 18px;
        height: 18px;
        border-radius: 0.1px;
        z-index: 0;
      }

      &__label {
        font-size: 16px;
        margin-left: 40px;
        cursor: pointer;
      }

      &__color {
        display: inline-block !important;
      }
    }
  }

  &_square {
    .checkbox {
      &__label:before {
        width: 20px;
        height: 20px;
        border-radius: 0.1px;
        z-index: 0;
      }

      &__label:after {
        width: 18px;
        height: 18px;
        border-radius: 0.1px;
        z-index: 0;
      }

      &__label {
        font-size: 16px;
        margin-left: 40px;
        cursor: pointer;
      }

      &__input {
        appearance: none;
        position: absolute;
        margin-left: -20px;
        width: 25px;
        height: 22px;
        z-index: 100;
        top: -3px;
        left: 16px;
        color: #EFF0F6;
        cursor: pointer;
      }

      &__input:disabled {
        cursor: initial;
      }
    }
  }

  .d {
    margin-left: 30px;
    margin-top: -26.5px;
  }

  &__input {
    appearance: none;
    position: absolute;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    z-index: 100;
    top: -2px;
    left: 16px;
    color: #EFF0F6;
    cursor: pointer;
  }

  &__label {
    font-size: 16px;
    margin-left: 40px;
    cursor: pointer;
  }

  &__label:before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-color: $btn-border;
    border-radius: 32px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: #EFF0F6;
  }

  &__label:after {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: url("../../assets/img/icons/checkmarks/check_white.svg") no-repeat;
    background-position: center;
    background-size: 17px 11px;
    opacity: 0;
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 2;
  }

  &__input:checked + &__label:before {
    background-color: $day-color;
  }

  &__input:checked + &__label:after {
    opacity: 1;

  }

  &__input:not(:disabled):checked + &__label::after {
    box-shadow: 0 0 0 0 $background-checked;
  }

  &__input:not(:disabled):checked + &__label::before {
    box-shadow: 0 0 0 0;
  }

  &__input:disabled:checked + &__label::before {
    background-color: $background-checked;
  }

  &__input:not(:disabled):not(:checked) + &__label::before {
    box-shadow: 0 0 0 0 $background-not-disabled;
  }

  &__input:disabled:not(:checked) + &__label::before {
    background-color: $background-disabled;

  }
}