.td-doc {
  .count {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: -7px;
    background: rgba(168,180,177,0.6);
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }

  .doc {
    line-height: 1.5;
    display: flex;

    &-img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 8px;
    }
  }

  .doc-long {
    &-img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: -7px;
    }
  }
}