@import "../../../assets/sass/var";

.main {
  background-color: $main-background;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;

  .main_wrapper {
    .user_cont {
      width: 1198px;
      height: 83px;
      margin: 169px 120px 50px 122px;

      .user_wrapper {
        width: 287px;
        height: 55px;
        margin-bottom: 28px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .avatar {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background: $background-success;
        }

        .user_name_block {
          width: 214px;
          height: 52px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: center;

          .user_name_field1 {
            width: 214px;
            height: 23px;
            background: $background-success;
          }

          .user_name_field2 {
            width: 152px;
            height: 23px;
            background: $background-success;
          }
        }
      }
    }

    .user_cont::after {
      content: "";
      width: 1198px;
      height: 1px;
      margin: 0 auto;
      background-color: $background-success;
      display: block;
    }

    .title_img_wrapper {
      margin: 0 auto 55px auto;
      width: 200px;
      height: 39px;
    }

    .content_block {
      width: 790px;
      height: 772px;
      background: $btn-text-color;
      border-radius: 20px;
      margin: 0 auto 20px auto;
      padding-top: 60px;

      .content_title {
        font-weight: bold;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.3px;
        color: $btn-background;
        margin: 0 0 20px 0;
      }

      .text_wrapper {
        height: 48px;
        padding-left: 80px;
        padding-right: 80px;
        text-align: center;

        .text {
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          letter-spacing: 0.25px;
          color: $btn-background;
        }
      }

      .date_block {
        width: 389px;
        height: 70px;
        margin: 30px 200px 30px 201px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        background: $btn-text-color;
        border: 2px solid $btn-color-gray;
        box-sizing: border-box;
        border-radius: 12px;

        .time_img {
          width: 22px;
          height: 22px;
          mix-blend-mode: multiply;
        }

        .date_value {
          position: static;
          width: 312px;
          height: 30px;
          left: 56px;
          top: 20px;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.25px;
          color: $btn-background;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin-left: 12px;

          .day {
            font-weight: bold;
            color: $day-color;
          }
        }
      }

      .content_block_two {
        width: 630px;
        height: 67px;
        margin-bottom: 30px;
        margin-left: 80px;
        margin-right: 80px;
        text-align: center;

        & .text {
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: $btn-background;
        }
      }

      .button {
        width: 176px;
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 60px;
        background: $btn-background;
        border-radius: 10px;
        border: none;
        margin-left: 307.5px;
        margin-right: 306.5px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $btn-text-color;
      }

      .covid_block {
        width: 670px;
        height: 193px;
        background: $main-background;
        border-radius: 10px;
        margin: 60px;
        padding: 20px 20px 30px 20px;

        .covid_title {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: $btn-background;
          padding: 0 0 0 20px;
        }

        .covid_text_wrapper {
          width: 630px;
          height: 118px;
          margin: 0 20px 30px 20px;

          .covid_text {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.25px;
            color: $paragraph-text-background;
          }
        }
      }
    }

    .footer_block {
      width: 400px;
      height: 21px;
      margin: 40px auto 106px auto;

      .footer_text {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.25px;
        color: $paragraph-text-background;
      }
    }
  }
}
