.button-close-component {
  border: none;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  border-radius: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #FFFFFF;
  cursor: pointer;

  img {
    width: 22px;
    height: 22px;
  }
}