@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.full-width {
  margin-left: -290px;
}

.branches {
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;

    &__title {
      margin-left: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    &__add {
      font-weight: bold;
      font-size: 16px;
      margin-left: 40px;
    }
  }

  &__table {
    position: relative;
    table-layout: fixed;
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    .thead {
      .th {
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #737484;
        border: solid $background-border-table;
        border-width: 1px 0;
      }
    }

    .tbody {
      position: inherit;
      .tr {
        background: $background-even;

        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n+1) {
          border-bottom: 1pt solid $background-border-table;
        }

        .td {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
          .address {
            margin-top: 0;
          }
        }

        .td-doc {
          padding: 20px 20px;
          padding-left: 40px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
          display: flex;
          justify-content: start;
          align-items: center;
          overflow: hidden;
        }

        .td-address-doc {
          padding: 20px 20px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;

          .address {
            margin-top: 0;
          }
        }

        .td-btns {
          padding: 20px 20px;
          text-align: right;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
        }

        .td-name {
          padding: 20px 30px;
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
        }

        .td-name-doc {
          padding: 20px 30px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
        }

        .button {
          margin-left: 15px;
        }
      }

      .tr:hover {
        background: rgba(219, 217, 217, 0.459);
      }
    }
  }

  &__wrapper-iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.199);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .iframe {
      border: none;
      padding: 20px;
      border-radius: 5px;
      background: $main-background;
      width: 700px;
      transition: 1s all;

      .wrap-close-button {
        float: right;
        margin-right: -27px;
        margin-top: -27px;
      }

      .area__text {
        padding: 10px 20px 10px 20px;
        height: 110px;
      }

      .btn-copy {
        float: right;
      }
    }
  }
}