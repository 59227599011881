.dateTimePicker {
  height: 60px;

  .react-datepicker {
    border-radius: 13px !important;
    border: none !important;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;

    &__calendar--open {
      top: 100% !important;
      bottom: unset !important;
    }

    &-wrapper {
      height: 60px !important;
    }

    &__input-container {
      height: 60px !important;
    }

    &__week {
      margin: 3px !important;
    }

    &__month-container {
      border-right: 1px solid #adadad !important;
      padding: 10px !important;
    }

    &-popper[data-placement^=top] {
    }

    &-popper[data-placement^=bottom] {
      padding-top: 18px !important;
    }

    &-popper {
    }

    &__triangle {
      display: none !important;
      left: -8px !important;
    }

    &__triangle::after {
      border-bottom-color: white !important;
    }

    &__triangle::before {
      border-bottom-color: white !important;
    }

    &__header {
      text-transform: capitalize;
      background-color: white !important;
      border: none !important;

      &--time {
        margin-top: 45px !important;
        margin-bottom: 10px !important;
      }
    }

    &__current-month {
      &--hasYearDropdown {
      }
    }

    &__day {
      &--outside-month {
        color: gray !important;
      }

      &--weekend {
        color: red !important;
      }

      &-name {
        font-weight: bold;
        font-size: 14px;
      }

      &-names {
        margin-top: 10px;
      }

      &--today {
        border-radius: 7px !important;
        background-color: white !important;
        color: black !important;
      }

      &--selected {
        background-color: #49CD6E !important;
        color: white !important;
        font-weight: bold !important;
      }

      &--keyboard-selected {
        background-color: #49CD6E !important;
        color: white !important;
        font-weight: bold !important;
      }
    }

    &__navigation {
      margin-top: 10px !important;

      &-icon::before {
        border-width: 1.5px 1.5px 0 0 !important;
      }
    }

    &__time {
      &-container {
        border: none !important;
      }

      position: absolute !important;

      &-list {
        height: 187px !important;
        margin-left: 10px !important;

        &-item {
          margin: 7px;
        }

        &-item {
          padding: 7px 10px !important;
          width: 50px;

          &--selected {
            background-color: #49CD6E !important;
            border-radius: 7px;
            padding: 7px 10px !important;
            width: 50px;
          }
        }

        &-item:hover {
          border-radius: 7px !important;
          padding: 7px 10px !important;
          width: 50px;
        }
      }
    }
  }

}
