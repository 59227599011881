@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.full-width {
  margin-left: -290px;
}

.schedule-work {
  padding-bottom: 40px;
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__title-wrapper {
    display: flex;

    .title {
      margin-left: 30px;
      margin-bottom: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }
  }

  &__table-wrapper {
    .branch-title {
      margin-left: 30px;
      margin-bottom: 10px;
    }

    .table {
      border: 0;
      width: 100%;
      height: 100%;
      border-spacing: 0;
      margin-bottom: 40px;

      .thead {
        .th {
          padding: 30px 0;
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: $btn-background;
          border: solid $background-border-table;
          border-width: 1px 0;
        }
      }

      .tbody {
        .branch {
          .td {
            text-align: center;
            border-right: 1px solid $border-table;
            padding: 10px 15px;
            font-size: 14px;
            vertical-align: top;
            border-bottom: 1px solid $border-table;

            .weekend {
              margin: 95px 0 95px 0;

              .weekend-hidden {
                visibility: hidden;
              }
            }

            .lunch-hidden {
              visibility: hidden;
            }
          }
        }
      }
    }

    .btn-wrapper {
      max-width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      margin: 10px auto;
    }
  }
}