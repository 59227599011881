@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

.schedule {
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__title-wrapper {
    display: flex;
    margin-bottom: 40px;
    justify-content: start;

    .title {
      margin-left: 30px;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    .wrap {
      &:hover {
        cursor: pointer;
      }

      .arrow {
        margin-left: 22px;
        top: 22px;
        left: 0;
      }

      .arrow span {
        top: 0;
      }
    }
  }

  .header {
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
    margin-top: -110px;
    margin-bottom: 110px;

    .nav {
      display: flex;
      float: right;
      width: 500px;
      height: 40px;
      margin-right: 20px;

      &:empty {
        width: 500px;
        background: #e1e1e1;
      }

      .nav-current {
        float: left;
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.3px;
        padding: 5px 30px;
        border-right: 2px solid $btn-border;
        width: 180px;

        &:empty {
          background: #e9e9e9;
          border-radius: 10px;
          width: 180px;
          height: 30px;
          margin-top: 5px;
          border: none;
        }
      }

      .nav-week {
        width: 200px;
        float: left;
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.3px;
        margin: 5px 30px;

        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .calendar-img {
          margin-right: 8px;
          margin-top: 3px;
          width: 24px;
          height: 24px;
        }

        &__date {
          display: flex;
          flex-direction: row;

          .dash {
            margin-top: -2px;
          }
        }


        &:empty {
          background: #e9e9e9;
          border-radius: 10px;
          width: 200px;
        }
      }

      .nav-week:hover {
        cursor: pointer;
      }

      .nav-prev {
        margin-right: 15px;
        padding: 5px 0;
        float: left;
        width: 24px;
        height: 24px;

        &:empty {
          margin-top: 8px;
          background: #e9e9e9;
          border-radius: 10px;
        }
      }

      .nav-prev:hover {
        cursor: pointer;
      }

      .nav-next {
        margin-right: 20px;
        padding: 5px 0;
        float: left;
        width: 24px;
        height: 24px;

        &:empty {
          margin-top: 8px;
          background: #e9e9e9;
          border-radius: 10px;
        }
      }

      .nav-next:hover {
        cursor: pointer;
      }
    }
  }

  &__calendar {
    display: block;
    margin-top: -70px;

    .preloader-wrap {
      position: absolute;
      top: 104px;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .preloader-wrap-open-sidebar {
      left: 290px;
      width: calc(100% - 290px);
    }

    .timeLine {
      display: block;
      position: absolute;
      padding-top: 60px;
      background: $background-even;
      font-size: 16px;
      font-family: Poppins, sans-serif;
      color: $paragraph-text-background;
      width: 100%;

      .time-title {
        font-family: 'PoppinsRegular';
        position: absolute;
        top: 0;
        width: 100px;
        text-align: center;
        height: 60px;
        margin-bottom: 20px;
        padding-top: 5px;
        background: #F6F6F8;
        border-top: 1px solid $border-table-time-line;
        border-bottom: 1px solid $border-table-time-line;
        font-weight: 600;
        color: #2A2A30;
        font-size: 16px;
        line-height: 24px;
      }

      .line {
        padding: 10px 0 0 30px;
        display: block;
        width: 100%;
        height: 180px;
        box-sizing: border-box;

        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n+1) {
          border-bottom: 1pt solid $background-border-table;
        }
      }
    }

    .timeLine-open-sidebar {
      width: calc(100% - 290px);
    }
  }

  &__wrapper-calendar-general {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: $background-popup-window;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .react-calendar {
      border-radius: $border-radius-popup !important;
      padding: 20px !important;
      background: $main-background !important;
      height: 520px !important;
    }
  }
}

.full-width {
  margin-left: -290px;
}