@import "../../assets/sass/var";

* {
  box-sizing: border-box;
}

.field {
  height: 105px;
  position: relative;

  &_focus {
    .field {
      &__label {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .field {
      &__label {
        transition: 0s;
      }
    }
  }

  &_blur {
    .field {
      &__label {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .field {
      &__label {
      }
    }
  }

  &_focus {
    .field {
      &__labels {
        top: 0;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .field {
      &__labels {
        transition: 0s;
      }
    }
  }

  &_blur {
    .field {
      &__labels {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .field {
      &__labels {
      }
    }
  }

  &__input {
    height: 64px;
    width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $general-error-text-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0;
  }

  &__input:focus {
    outline: none !important;
    border: 2px solid $border-field-focus;
  }

  &__input:hover {
    border: 2px solid $border-field-focus;
  }

  &__label {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  /////////////////////////////color
  .color-name-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 62px;

    &:hover {
      cursor: pointer;
    }

    .color-name {
      position: absolute;
      top: 28px;
      left: 60px;
      color: $paragraph-text-background;
    }
  }

  .twitter-picker {
    margin-top: 10px;
    float: right;
    padding-bottom: 5px;
    border-radius: 12px !important;

    div {
      div {
        display: none !important;
      }
    }

    div {
      span {
        div {
          display: inherit !important;
          background: #ae1412;
          border-radius: 50% !important;
        }
      }
    }

  }

  ////////////////////////////////////////////
  &__imgNone {
    display: none !important;

  }

  &__img {
    position: absolute;
    float: right;
    right: 10px;
    top: 10px;
    padding: 10px;

  }

  .react-calendar {
    &__century-view {
      &__decades {
        width: 291px !important;
        justify-content: center !important;

        &__decade {
          overflow: visible !important;
        }
      }
    }

    &__month-view {
      &__days {
        &__day {
        }
      }
    }

    &__year-view {
      &__months {
        width: 307px !important;

        &__month {
          overflow: visible !important;
        }
      }
    }

    &__decade-view {
      &__years {
        justify-content: center;

        &__year {
          overflow: visible !important;
        }
      }
    }

    &__tile {
      &--hasActive {
        background: #49CD6E !important;
        color: white !important;
        padding: 5px !important;
      }
    }

    margin-top: 10px;
    right: 0;
    background-color: white;
    padding: 15px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 13px;
    position: absolute;
    z-index: 100 !important;
    width: 320px !important;

    &__navigation {
      height: 20px !important;

      &__label:hover {
        background-color: transparent !important;
      }

      &__label:focus {
        background-color: transparent !important;
      }

      &__label {
        background-color: transparent !important;
      }

      &__arrow {
        background-color: transparent !important;
      }

      &__arrow:focus {
        background-color: transparent !important;
      }

      &__arrow:hover {
        background-color: transparent !important;
      }

      &__prev-buttom {
        background-color: transparent !important;

      }

      &__prev-buttom:hover {
        background-color: transparent !important;

      }

      &__prev-buttom:focus {
        background-color: transparent !important;

      }
    }

    abbr {
      font-size: 14px !important;
    }

    button {
      font-size: 14px !important;
      margin-bottom: 0 !important;
      padding: 5px !important;
      padding-left: 5px;
      border-radius: 0;
    }

    &__month-view {
      &__weekdays__weekday {
        margin: 0 !important;
      }
    }
  }

  &__labels {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__select {
    min-height: 64px;
    width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $general-error-text-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0px;
  }

  &__option {
    margin-top: 10px;
  }

  &__clear :hover {
    opacity: 0.7;
  }

  &__clear {
    color: $btn-clear;
    position: absolute;
    top: 20px;
    right: 29px;
    z-index: 1;
  }

  &_datePicker {
    .field {
      &__label {
        position: absolute;
        top: 0;
        left: 18px;
        margin: 8px;
        letter-spacing: 0.4px;
        transition: 0s;
        font-size: 14px;
        color: $paragraph-text-background;
      }

      &__input::placeholder {
        color: black;
      }
    }
  }

  &_order {
    .field {
      &__input {
        margin-bottom: 2px;
        position: relative;
        height: 32px;
        width: 103%;
        background-color: #EFF0F6;
        padding: 1px 1px;
        color: $btn-background;
        border: none;
        font-weight: normal;
        cursor: text;
        border-radius: 10px;
        text-align: center;
        font-size: 15px;
        box-sizing: border-box;
        outline: none;
      }

      &__input:hover {
        border: 1.5px solid $btn-background;
        background-color: $btn-text-color;
      }

      &__input:focus {
        height: 32px;
        border: 1.5px solid #49CD6E;
        background-color: $btn-text-color;
      }

      &__label {
        font-weight: lighter;
        margin: 0;
        display: flex;
        justify-content: center;
        font-size: 13px;
        position: relative;
        top: -30px;
        left: 0;
      }

      &__labels {
        opacity: 0;
        font-weight: lighter;
        margin: 0;
        display: flex;
        justify-content: center;
        font-size: 13px;
        position: relative;
        top: -32px;
        left: 0;
      }
    }
  }

  &_active {
    .field {
      &__input:focus {
        outline: none !important;
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__help-text {
        font-size: 14px;
        letter-spacing: $help-text-spacing;
        color: #737484;
      }

      &__input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px $btn-text-color !important;
        -webkit-text-fill-color: $btn-background !important;
        color: $btn-background !important;
      }
    }
  }

  &_date {
    .field {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_datetime {
    .field {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        outline: none !important;
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_time {
    .react-calendar {
      display: none;
    }

    .field {
      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input:hover {
        outline: none !important;
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }

  &_typing {
    .field {
      &__input:focus {
        outline: none !important;
        border: 2px solid $border-field-focus;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $border-field-focus;
        background-color: $btn-text-color;
      }
    }
  }

  &_disabled {
    .field {
      &__input {
        background-color: $main-background;
      }
    }
  }

  &_caption {
    .field {
      &__input {
        margin-bottom: 9px;
      }

      &__help-text {
        font-size: $label-font-size;
        letter-spacing: $help-text-spacing;
        color: $paragraph-text-background;
      }

      &__clear {
        margin-top: -75px;
      }
    }
  }

  &_search {
    .field {
      &__input {

        background: url("../../assets/img/icons/search.svg") no-repeat;
        background-position: 18px 16px;
        background-size: 20px;
        padding: 52px;
        border: none;
        background-color: #F6F6F8;
        padding-bottom: 0;
      }


      &__label {
        font-size: 14px;
        left: 44px;
        margin-top: 3px;
      }

      &__labels {
        left: 18px;
        margin-top: 1px;
      }
    }
  }

  &_user {
    .field {
      &__input {
        background-image: url("../../assets/img/icons/profile.svg");
        background-repeat: no-repeat;
        background-position: 20px;
        background-color: transparent;
        padding: 25px 57px;
      }

      &__label {
        left: 50px;
      }
    }
  }

  &_success {
    .field {
      &__input {
        background-color: #f2fffb;
        border: 2px solid #00ba88;
      }

      &__help-text {
        font-size: $label-font-size;
        letter-spacing: $help-text-spacing;
        color: #00966d;
      }
    }
  }

  &_error {
    .field {
      &__input {
        background-color: $input-color-error;
        border: 2px solid $input-border-error;
      }

      &__label {
        color: $input-color-help-text;
      }

      &__clear {
        margin-top: -69px;
        color: $input-border-error;
      }

      &__help-text {
        letter-spacing: $help-text-spacing;
        font-size: $label-font-size;
        color: $input-color-help-text;
        padding-top: -20px;
      }
    }
  }
}

.order {
  height: 57px !important;
  margin-bottom: -27px;

  &__label {
    margin: 0;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #737484;
    position: relative;
    cursor: text;
    top: -28px;
    left: 0px;

  }

  &_focus {
    .order {
      &__label {
        opacity: 0;
        transition: 0s;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .order {
      &__label {
        transition: 0s;
      }
    }
  }

  .react-calendar {
    display: none;
  }
}

input[type="color"]::-webkit-color-swatch-wrapper {
  margin-top: 2px;
  width: 28px;
  height: 31px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}