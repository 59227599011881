@import "../../../assets/sass/var";

.edit-window {
  width: 100%;
  min-height: 150%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.028);

  &__alert {
    .btn-close-block {
      float: right;
      margin-right: -37px;
      margin-top: -37px;

      .button-close-component {
        box-shadow: none;
      }
    }

    width: 400px;
    position: absolute;
    left: 40%;
    top: 7%;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    max-width: 100%;
    border: none;
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
    padding: 30px;
    background: $main-background;
    border-radius: $border-radius-popup;

    .title {
      position: relative;
      max-width: 100%;
      margin: 0;
      padding-right: 160px;
      padding-bottom: 24px;
      color: inherit;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
      font-style: normal;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.3px;
    }

    .title-de {
      position: relative;
      max-width: 100%;
      margin: 0;
      padding-right: 80px;
      padding-bottom: 24px;
      color: inherit;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
      font-style: normal;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.3px;
    }

    .input-wrapper {
      .input-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 50px;

        .span {
          padding-top: 15px;
        }

        .input {
          width: 220px;
          margin-left: 65px;
        }
      }

      .checkbox-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;

        .checkbox {
          margin-top: 5px;
        }
      }
    }

    .btn-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      .buttonAlert {
        margin-left: 15px;
      }
    }
  }
}