.preloader {
  &__event {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 84px;
    min-height: 200px;
    background: rgba(69, 69, 69, 0.121);
    mix-blend-mode: multiply !important;
    z-index: 100;

    &-img {
      position: absolute;
      right: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      mix-blend-mode: multiply !important;
    }
  }
}
