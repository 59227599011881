@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.full-width {
  margin-left: -290px;
}

.clients {
  padding-bottom: 40px;
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;


  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;

    &__title {
      margin-left: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    &__add {
      font-weight: bold;
      font-size: 16px;
      margin-left: 40px;
    }
  }

  .not-appeared {
    color: #fd510d;
    font-weight: bold;
    white-space: nowrap;
    font-size: 12px;
  }

  &__table {
    position: relative;
    table-layout: fixed;
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    .thead {
      .th {
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #737484;
        border: solid $background-border-table;
        border-width: 1px 0;
      }

      .th_title {
        color: #737484;
      }

      .th-options {
        width: 153px;
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #737484;
        border: solid $background-border-table;
        border-width: 1px 0;

      }
    }

    .tbody {
      position: inherit;
      .tr {
        background: $background-even;

        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n+1) {
          border-bottom: 1pt solid $background-border-table;
        }

        .td {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
          word-break: break-word;
        }

        .td-doc {
          padding: 20px 20px;
          padding-left: 40px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
          display: flex;
          justify-content: start;
          align-items: center;
          overflow: hidden;
        }

        .td-btns {
          text-align: right;
        }

        .td-name {
          padding: 20px 30px;
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
        }

        .tdBlock {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #fd510d;
        }

        .tdBlock-name {
          padding: 20px 30px;
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #fd510d;
        }

        .button {
          margin-left: 15px;
        }
      }

      .tr:hover {
        background: rgba(219, 217, 217, 0.459);
      }
    }
  }
}