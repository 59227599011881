@import "../../../assets/sass/var";

.events-details-window {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: $background-popup-window;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &__wrap {
    border-radius: $border-radius-popup;
    transition: .5s all;
    position: relative;
    top: 0;
    left: 0;

    .btn-close-block {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;
    }

    &__alert {
      border-radius: $border-radius-popup;
      background: $main-background;
      min-width: 700px;
      max-width: 95%;
      max-height: 96vh;
      transition: .5s all;
      position: relative;
      top: 0;
      left: 0;
      overflow: scroll;

      &__event-block {
        width: calc(100% - 80px);
        margin: 10px 40px 20px 40px;

        &__header {
          width: 100%;
          border-bottom: 1px solid #E7E6E6;
          display: flex;
          justify-content: space-between;
          padding-bottom: 20px;

          &_time-block {
            height: 30px;
            margin-top: 22px;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.3px;
            color: #2A2A30;
          }

          &_date-block {
            margin-top: 18px;

            &_date {
              display: inline-block;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: right;
              letter-spacing: 0.25px;
              color: #737484;
            }
          }
        }

        .preloader-wrap-client {
          position: relative;
          top: -85px;

          .preloader {
            &__event {
              background: none;
            }
          }
        }

        &__client-block {
          width: 100%;
          border-bottom: 1px solid #E7E6E6;
          margin-top: 20px;
          border-spacing: 0;
          border-collapse: collapse;

          &_title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.3px;
            color: #2A2A30;
            margin-top: 20px;
            width: 100%;

            .tr {
              .th {
                text-align: left;

                .not-appeared {
                  color: #fd510d;
                  font-weight: bold;
                  white-space: nowrap;
                  font-size: 12px;
                  margin-left: 20px;
                }
              }
            }
          }

          .tbody {
            .tr {
              height: 24px;

              .td {
                width: 50%;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.25px;
                color: #2A2A30;
                height: 24px;
                padding-top: 8px;
              }

              .td-btn {
                text-align: right;
                padding-top: 20px;

                .btn-edit {
                  margin-left: 20px;
                  margin-bottom: 20px;
                  margin-right: -1px;
                }
              }
            }
          }

          .tbody-loading {
            opacity: 0.5;
          }
        }

        .preloader-wrap-event {
          position: relative;
          top: -60px;

          .preloader {
            &__event {
              background: none;
            }
          }
        }

        &__event-data {
          width: 100%;
          border-bottom: 1px solid #E7E6E6;
          margin-top: 20px;
          padding-bottom: 20px;

          &_title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.3px;
            color: #2A2A30;
            margin-top: 20px;
            width: 100%;

            .tr {
              .th {
                text-align: left;
              }
            }
          }

          .tbody {
            .tr {
              .td {
                width: 50%;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.25px;
                color: #2A2A30;
                height: 24px;
                padding-top: 8px;
              }

              .td-btn {
                text-align: right;
                padding-top: 20px;

                .btn-edit {
                  margin-left: 20px;
                  margin-right: -3px;
                }

                .btn-left {
                  margin-left: 20px;
                }

                .btn-right {
                  margin-left: 20px;
                  margin-right: -3px;
                }

                .btn-one {
                  margin-right: -3px;
                  margin-left: 20px;
                }
              }
            }
          }

          .tbody-loading {
            opacity: 0.5;
          }
        }
      }

      &__client-history {
        width: calc(100% - 3px);
        height: 220px;
        margin-top: 20px;
        overflow: scroll;
        overflow-x: hidden !important;

        &::-webkit-scrollbar {
          width: 5px;
          background: $main-success-background;
          border-radius: 10px;
          margin-right: -5;
        }

        &::-webkit-scrollbar-thumb {
          background: #a0a096;
          border-radius: 10px;
        }

        &_title {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.3px;
          color: #2A2A30;
          width: 100%;
          margin-left: 40px;
          margin-bottom: 8.75px;
        }

        .table {
          width: 100%;
          border-spacing: 0;
          border-collapse: collapse;

          .thead {
            border-top: 1px solid #EFF0F6;
            border-bottom: 1px solid #EFF0F6;

            .tr {
              .th {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.25px;
                color: #737484;
                text-align: center;
                height: 56px;
              }
            }
          }

          .preloader-wrap-history {
            position: absolute;
            width: 100%;
            margin-top: -120px;

            .preloader {
              &__event {
                background: none;
              }
            }
          }

          .tbody {
            .tr {
              background: $background-even;

              &:nth-of-type(even) {
                background: $main-background;
              }

              .td {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.25px;
                color: #2A2A30;
                text-align: center;
                height: 56px;
                width: 18%;

                &:nth-last-child(1) {
                  width: 180px;
                }
              }

              .td-doctor {
                width: 28%;
              }

              .td-reason {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.25px;
                color: #2A2A30;
                text-align: center;
                height: 56px;
                border-left: 2px solid #000000;
                width: 18%;
              }
            }
          }

          .tbody-loading {
            opacity: 0.5;
          }
        }
      }

      .btn-history {
        margin: 24px 40px 10px 0;
      }
    }
  }
}