@import "../../../assets/sass/var";

.main {
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;

  &__content {
    &-img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      mix-blend-mode: multiply;

    }

    &-title {
      font-family: "PoppinsBold";
      margin-top: 40px;
      margin-bottom: 4px;
      text-align: center;
      font-weight: bolder;
      font-size: 32px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    &-subtitle {
      margin-top: 0px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      text-align: center;
      letter-spacing: 1px;
      color: $paragraph-text-background;
    }

    &-btn {
      margin-top: 60px;
    }
  }
}
