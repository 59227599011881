@import "src/assets/sass/var";

.copy-script-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.199);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 1s all;
  opacity: 1;

  .copy-content {
    background: $main-background;
    padding: 10px 25px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 12px;
    transition: 1s all;
  }
}

.close-copy-alert {
  transition: 1s all;
  opacity: 0;
}