@import "src/assets/sass/var";

.order-filter {
  display: block;
  width: 290px;
  grid-area: navbar;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  padding-bottom: 100px;

  margin-top: 100px;

  .search {
    height: 87px !important;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: -30px;
    width: 233px;
  }

  .color {
    width: 11px;
    height: 11px;
    border-radius: 12px;
    margin-top: 5px;
    margin-bottom: 39px;

  }

  .filter-block {
    .css-1afjkum-control {
      border: 1px solid #D6D8E7;
    }

    .css-jbpbp8-Input {
      width: 0;
    }

    .css-18h66qw-control:hover {
      border: 1px solid black;

    }

    .css-18h66qw-control {
      border: 1px solid #D6D8E7;

    }

    .css-1okebmr-indicatorSeparator {
      width: 0;
    }

    .css-qc6sy-singleValue {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    .css-1y8ltyy {
      width: 0 !important;
    }

    .css-jbpbp8-Input {
      width: 0 !important;
    }

    .css-134cg2f {
      padding: 2px 27px !important;
      left: -5px !important;
    }

    .css-kws6uf-ValueContainer {
      padding: 2px 31px !important;
      left: -8px !important;

    }

    &-select {
      width: 233px !important;
      margin-left: 29px !important;
      margin-bottom: -20px !important;
    }

    display: flex;
    flex-direction: column;
    align-content: stretch;

    .filter-title {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 20px 30px 23px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;

      .filter-title-name {
        width: 71.5%;
      }

      cursor: pointer;

      .arrow-event-filter1 {
        margin-left: 140px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .arrow-event-filter {
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }

    .filter-title2 {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 31px 30px 23px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;

      .filter-title-name {
        width: 71.5%;
      }

      cursor: pointer;

      .arrow-event-filter1 {
        margin-left: 140px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .down {
        transform: rotate(0deg);
        transition: 0.3s;
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .up {
        transform: rotate(-180deg);
        transition: 0.3s;
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }


    .filter-title1 {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin: 20px 30px 10px 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;

    }

    .filter-param {
      margin: 0 30px -10px 30px;
    }

    .filter-param.param-branch {
      margin: 10px 30px -40px 30px;
    }

    .filter-param:hover {
      cursor: pointer;
    }

    .filter-param-none {
      margin: 0 30px -10px 30px;
    }

    .app {
      &__text {
        width: 100%;
      }


      &__collapse {
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
        transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

        &--gradient {
          .app__content {
            border-color: transparent;
          }

          &:after {
            pointer-events: none;
            transition: opacity 300ms;
            will-change: opacity;
            opacity: 1;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(transparent 50%, white);
          }
        }

        &--active {
          &:after {
            opacity: 0;
          }
        }
      }

      &__content {
      }

      &__toggle {
        width: 100%;
        transition: background-color 300ms;
        display: flex;
        align-items: flex-end;
        margin: 31px 30px 23px 30px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.3px;
        cursor: pointer;

        .cross {
          width: 20px;
          height: 20px;
          position: relative;
          right: 5px;
          top: 50%;
          transform: translateY(0%);

          &::after,
          &::before {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            background-color: black;
            width: 15px;
            top: 50%;
            transition: all .2s ease-in-out;
            right: 0;
          }

          &::after {
            transform: rotate(90deg);
            left: 5px;
          }

          &.active {
            &::before {
              width: 9px;

            }

            &::after {
              width: 8px;
              transform: rotate(0deg);
              left: 5px;

            }
          }
        }

        .arrow {
          width: 4px;
          height: 4px;
          display: inline-block;
          position: relative;
          top: -10px;
          left: 5px;
        }

        .arrow span {
          top: 8px;
          position: absolute;
          width: 10px;
          height: 1.6px;
          background-color: black;
          display: inline-block;
          transition: all 0.2s ease;
        }

        .arrow span:first-of-type {
          left: 0;
          transform: rotate(45deg);
        }

        .arrow span:last-of-type {
          right: 0;
          transform: rotate(-45deg);
        }

        .arrow.active span:first-of-type {
          transform: rotate(-45deg);
        }

        .arrow.active span:last-of-type {
          transform: rotate(45deg);
        }

        .down {
          transform: rotate(0deg);
          transition: 0.3s;
          align-items: flex-end;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }

        .up {
          transform: rotate(-180deg);
          transition: 0.3s;
          align-items: flex-end;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }

        .filter-title-name {
          width: 71.5%;
        }

        &--active {
        }
      }
    }
  }
}