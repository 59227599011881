@import "src/assets/sass/var";

.register {
  overflow: auto;
  background-color: #f6f6f8;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .img-wrapper {
    margin: 75px auto;
    width: 300px;

    .img {
      margin: 0;
      width: 300px;
    }
  }

  .add-register {
    margin-bottom: 30px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 7px 7px 20px rgb(0 0 0 / 5%);
    padding: 35px 40px;
    overflow: auto;
    width: 800px;

    &__title {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      margin: 10px 0 10px;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    &__inputs-label {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5;
      color: $btn-background;
      padding-bottom: 60px;
    }

    &__inputs-branch {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      margin-bottom: 40px;
      margin-top: 10px;
    }

    &__inputs-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: -15px;
      grid-column-gap: 20px;
      margin-top: 10px;

      .input {
        margin-bottom: -33px !important;
      }
    }

    &__inputs-setting {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      margin-top: 10px;
    }

    &__inputs-setting1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      margin-top: 10px;
      margin-bottom: 35px;
    }

    .button {
      &_color-green {
        background-color: $day-color;
        color: $btn-text-color;
      }
    }

    &__form {
      .fieldset {
        display: block;
        width: 100%;
        overflow: hidden;
        clear: both;

        .field {
          width: 50%;
          float: left;
          padding-right: 25px;
        }
      }

      .general-error {
        letter-spacing: $help-text-spacing;
        font-size: $general-error-text-size;
        color: $input-color-help-text;
        text-align: center;
      }

      .button {
        margin-left: 20px;
      }

      ._button-wrapper {
        width: 100%;
        margin-top: -35px;
      }
    }
  }

}

@media screen and (max-width: 767px) {
  .register {
    overflow: auto;
    height: 100vh;
    position: fixed;

    .add-register {
      overflow: visible;
      padding: 0;
      padding-bottom: 30px;
      width: 350px;

      &__inputs-branch {
        display: block;
      }

      &__inputs-info {
        display: block;
        margin-bottom: -20px;
      }

      &__inputs-setting {
        display: block;
      }

      &__inputs-setting1 {
        display: block;
      }

      &__form {
        margin: 0 30px 40px;

        .button {
          margin-left: 0;
          padding: 10px 45px;
        }

        ._button-wrapper {
          width: 100%;
          margin-top: 0;
        }
      }
    }
  }

  .btn {
    width: 130px !important;
  }
}