.td-button-group {

  .app {
    &__text {
      width: 100%;
    }

    &__collapse {
      display: block;
      position: absolute;
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
      background-color: transparent;
      border-radius: 9px;
      right: 45px;
      filter: drop-shadow(0 0 10px rgb(0 0 0 / 10%));
      z-index: 1000;

      &--gradient {
        .app__content {
          border-color: transparent;
        }

        &:after {
          pointer-events: none;
          transition: opacity 300ms;
          will-change: opacity;
          opacity: 1;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &--active {
        &:after {
          opacity: 0;
        }
      }
    }

    &__content {
    }

    &__toggle {
      width: 100%;
      transition: background-color 300ms;
      padding-right: 36px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
      cursor: pointer;

      .btm-menu {
        margin-left: 82px !important;
        height: 18px;
      }

      .btm-menu-open {
        position: inherit !important;
        margin-left: 82px !important;
      }

      .btm-menu span {
        width: 17px;
        height: 2px;
      }

      .btm-menu span:nth-child(1) {
        top: 0;
      }

      .btm-menu span:nth-child(2),
      #nav-icon3 span:nth-child(3) {
        top: 7px;
      }

      .btm-menu span:nth-child(4) {
        top: 14px;
      }

      .btm-menu-open span:nth-child(1) {
        top: 22px;
        width: 0;
        left: 50%;
      }

      .btm-menu-open span:nth-child(2) {
      }

      .btm-menu-open span:nth-child(3) {
        top: 7px;
      }

      .btm-menu-open span:nth-child(4) {
        top: 14px;
        width: 0;
        left: 50%;
      }
    }
  }

  .options {

    display: flex;
    flex-direction: column;
    //position: relative;
    position: inherit;
    z-index: 100;
    background: white;
    padding: 6px 16px 16px 16px;
    border-radius: 12px;
    margin-top: 10px;
  }

  .actions {
    cursor: pointer;
    display: flex;
    padding-top: 15px;
    text-decoration: none;
    color: #737484;
    font-size: 14px;
    font-weight: normal;

    .img {
      cursor: pointer;
      width: 17px;
      height: 17px;
      margin-right: 7px;
      margin-top: -2px;
    }

    .load {
      margin-right: 7px;
      margin-top: 2px;
      width: 20px;
      height: 20px;
      background: url(../../../../assets/img/preloader.gif) no-repeat;
      background-size: 20px
    }

    .cross {
      cursor: pointer;
      width: 14px;
      height: 14px;
      margin-right: 10px;
      margin-top: 3px;

    }
  }

  .actions:hover {
    cursor: pointer;
    display: flex;
    color: #737484;
    font-size: 14px;
    text-decoration: underline solid #737484;

    .img {
      cursor: pointer;
      width: 17px;
      height: 17px;
      margin-right: 7px;
    }
  }

  .up {
    transition: 0.3s;
    transform: rotate(0deg);
    cursor: pointer;
    width: 15px;
    height: 15px;
  }

  .down {
    transition: 0.3s;
    transform: rotate((-180deg));
    width: 15px;
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
  }

  .down {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
}