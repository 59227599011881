.btm-menu {
  width: 20px;
  height: 17.5px;
  position: relative;
  max-height: 23px;


  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.btm-menu-open {
  position: fixed;
}

.btm-menu span {
  display: block;
  position: absolute;
  width: 20px;
  height: 1.5px;
  background: #3C3C3B;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.btm-menu span:nth-child(1) {
  top: 0;
}

.btm-menu span:nth-child(2), #nav-icon3 span:nth-child(3) {
  top: 8px;
}

.btm-menu span:nth-child(4) {
  top: 16px;
}

.btm-menu-open span:nth-child(1) {
  top: 22.5px;
  width: 0;
  left: 50%;
}

.btm-menu-open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btm-menu-open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 8px;
}

.btm-menu-open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}