@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.profile {
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
    margin-left: 30px;
    margin-bottom: 8px;
  }

  &__profile-update {
    width: 70%;
    margin: 0 40px 40px;

    .upload-avatar {
      position: relative;
      max-width: 205px;
      margin-bottom: 20px;

      &__edit {
        top: 8px;
        position: absolute;
        right: 10px;
        z-index: 1;

        .edit-input {
          display: none;
          overflow: visible;
          margin: 0;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          width: 30px;
          height: 30px;
        }

        .edit-label {
          display: inline-block;
          width: 34px;
          height: 34px;
          margin-bottom: 0;
          border-radius: 100%;
          border: 1px solid transparent;
          box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
          cursor: pointer;
          font-weight: normal;
          transition: all 0.2s ease-in-out;
          background-color: #ffffff;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      &__clear {
        top: 50px;
        position: absolute;
        right: 10px;
        z-index: 1;

        .clear-input {
          box-sizing: border-box;
          padding: 0;
          display: none;
          overflow: visible;
          margin: 0;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          width: 30px;
          height: 30px;
        }

        .clear-label {
          display: inline-block;
          width: 34px;
          height: 34px;
          margin-bottom: 0;
          border-radius: 100%;
          border: 1px solid transparent;
          box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
          cursor: pointer;
          font-weight: normal;
          transition: all 0.2s ease-in-out;
          background-color: #ffffff;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      &__preview {
        width: 200px;
        height: 200px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
        background-size: cover;
      }
    }

    .inputs-label {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5;
      color: $btn-background;
      padding-bottom: 20px;
    }

    .inputs-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      margin-top: 10px;
    }

    @media (min-width: 1300px) {
      width: 950px;
    }
  }

  &__status {
    height: 72px;
    width: 70%;
    margin: 100px 40px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: $btn-text-color;
      letter-spacing: 0.75px;
    }
  }

  &__status.success {
    background: $day-color;

    &:hover {
      cursor: pointer;
    }
  }

  &__status.failed {
    background: $text-weekend-color;

    &:hover {
      cursor: pointer;
    }
  }
}

.full-width {
  margin-left: -290px;
}