@import "src/assets/sass/var";

.full-width {
  margin-left: -290px;
}

.add-reason {
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
    margin-left: 30px;
    margin-bottom: 8px;
  }

  &__inputs-label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: $btn-background;
    padding-bottom: 20px;
  }

  &__inputs-branch {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 40px;
    margin-top: 10px;
  }

  &__inputs-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-top: 10px;
  }

  &__inputs-setting {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-top: 10px;
  }

  &__form {
    margin: 0 30px 40px;

    .fieldset {
      display: block;
      width: 100%;
      overflow: hidden;
      clear: both;

      .field {
        width: 50%;
        float: left;
        padding-right: 25px;
      }
    }

    .general-error {
      letter-spacing: $help-text-spacing;
      font-size: $general-error-text-size;
      color: $input-color-help-text;
      text-align: center;
    }

    ._button-wrapper {
      width: 100%;
      margin-top: 40px;
    }

    @media (min-width: 1300px) {
      width: 950px;
    }
  }

  .MuiTextField-root {
    margin-bottom: 41px;

    .MuiFilledInput-root {
      height: 64px;
      width: 100%;
      border: 2px solid #D6D8E7;
      letter-spacing: 0.75px;
      border-radius: 16px;
      background-color: $btn-text-color;
      font-size: $general-error-text-size;
      color: $btn-background;
      padding: 21px 24px;
      padding-bottom: 0;

      .MuiFilledInput-input {
        padding: 12px 12px 5px 0;
        margin-bottom: 7px;
      }

      input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px $btn-text-color !important;
        -webkit-text-fill-color: $btn-background !important;
        color: $btn-background !important;
      }
    }

    .MuiInputLabel-root {
      margin-top: 5px;
      margin-left: 14px;
      letter-spacing: 0.75px;
      font-size: 16px;
      color: $paragraph-text-background;
      font-family: 'PoppinsRegular';
    }

    .MuiInputLabel-shrink {
      margin-top: 0;
      letter-spacing: 0.75px;
      font-size: 20px;
      color: $paragraph-text-background;
      font-family: 'PoppinsRegular';
    }

    .MuiFilledInput-root:before {
      border-bottom: none;
    }

    .MuiFilledInput-root:after {
      border-bottom: none;
    }

    .MuiFilledInput-root:hover:before {
      border-bottom: none;
    }

    .MuiFilledInput-root:focus:before {
      border-bottom: none;
    }

    .MuiFormHelperText-root {
      letter-spacing: 0.25px;
      font-size: 14px;
      font-family: 'PoppinsRegular';
      position: absolute;
      top: 62px;
    }
  }

  .MuiTextField-root.MU-Field-error {
    .MuiFilledInput-root {
      background-color: $input-color-error;
      border: 2px solid $input-border-error;
    }
  }
}