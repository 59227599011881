@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.full-width {
  margin-left: -290px;
}

.containerNot {
  grid-area: content;
  padding-bottom: 40px;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__title {
    margin-left: 30px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
  }

  &__p {
    margin-top: -20px;
    margin-left: 30px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
  }

  &__btn {
    margin-left: -60px;
    margin-top: 25px;

    .btn {
      height: 56px;
      width: 161px;
      margin: 90px;
      justify-content: center;
      text-decoration: none;
      padding: 13px 28px;
      border-radius: 24px;
      background-color: transparent;
      color: black;
      font-size: 16px;
      letter-spacing: 0.6px;
      cursor: pointer;
      border: 2px solid black;
      font-weight: bold
    }

    .btn:hover {
      background-color: black;
      color: white;
    }
  }
}
