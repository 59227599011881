@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.full-width {
  margin-left: -290px;
}

.events {
  padding-bottom: 40px;
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;

    &__title {
      margin-left: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    &__add {
      font-weight: bold;
      font-size: 16px;
      margin-left: 40px;

      .add {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

  &__table {
    position: relative;

    table-layout: fixed;
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    .thead {
      .th {
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $btn-background;
        border: solid $background-border-table;
        border-width: 1px 0;
      }

      .th-options {
        width: 153px;
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $btn-background;
        border: solid $background-border-table;
        border-width: 1px 0;
      }
    }

    .tbody {
      .tr {
        background: $background-even;

        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n+1) {
          border-bottom: 1pt solid $background-border-table;
        }

        .td {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: $btn-background;
          word-break: break-word;
        }

        .td-name {
          padding: 20px 30px;
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: $btn-background;
          word-break: break-word;
        }

        .td-color {
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: $btn-background;
          border-left: 2px solid black;
          width: calc(100% - 10px);
          padding: 10px;
          box-sizing: border-box;
          z-index: 1;
          list-style: none;
          word-break: break-word;
        }

        .td-button-group {
          text-align: right;
        }

        .date-event {
          display: flex;
          flex-direction: column;
        }

        .time {
          white-space: nowrap;
        }

        .button {
          margin-top: 5px;
        }
      }

      .tr:hover {
        background: rgba(219, 217, 217, 0.459);
      }
    }
  }
}