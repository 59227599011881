/* poppins-300 - latin */
@font-face {
  font-family: 'PoppinsLight';
  font-style: normal;
  font-weight: 300;
  src: url('poppins-v19-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('poppins-v19-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('poppins-v19-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('poppins-v19-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('poppins-v19-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('poppins-v19-latin-300.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'PoppinsRegular';
  font-style: normal;
  font-weight: 400;
  src: url('poppins-v19-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('poppins-v19-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('poppins-v19-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('poppins-v19-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('poppins-v19-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('poppins-v19-latin-regular.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-family: 'PoppinsBold';
  font-style: normal;
  font-weight: 600;
  src: url('poppins-v19-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('poppins-v19-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('poppins-v19-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('poppins-v19-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('poppins-v19-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('poppins-v19-latin-600.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins500';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('poppins-v19-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('poppins-v19-latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600 !important;
  src: url('../../../../src/assets/fonts/montserrat/montserrat-v24-cyrillic-ext_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../../../src/assets/fonts/montserrat/montserrat-v24-cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../../../src/assets/fonts/montserrat/montserrat-v24-cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../../../src/assets/fonts/montserrat/montserrat-v24-cyrillic-ext_cyrillic-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../../../../src/assets/fonts/montserrat/montserrat-v24-cyrillic-ext_cyrillic-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../../../src/assets/fonts/montserrat/montserrat-v24-cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}