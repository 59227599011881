@import "../../../assets/sass/var";

.delete-window {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: $background-popup-window;
  display: flex;
  align-items: center;
  justify-content: center;

  &__alert {
    .btn-close-wrap {
      float: right;
      margin-right: -27px;
      margin-top: -27px;
    }

    padding: 20px;
    border-radius: $border-radius-popup;
    background: $main-background;
    width: 512px;
    height: 220px;
    transition: .5s all;

    .title {
      position: relative;
      max-width: 100%;
      margin: 0;
      padding: 0.8em 1em 0;
      color: inherit;
      font-size: 1.875em;
      font-weight: 600;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
    }

    .btn-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .button {
      margin: 5px;
    }
  }
}

