.preloader {
  &__waitingRoom {
    position: absolute;
    height: 100vh;
    width: 100%;
    min-height: 200px;
    background: rgba(69, 69, 69, 0.121);
    mix-blend-mode: multiply !important;
    z-index: 100;

    &-img {
      position: fixed;
      margin: auto;
      left: 280px;
      top: 300px;
      bottom: 0;
      right: 0;
      mix-blend-mode: multiply;
    }
  }
}
