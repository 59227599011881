.preloader {
  height: 100%;

  &-img {
    position: fixed;
    margin: auto;
    left: 280px;
    top: 300px;
    bottom: 0;
    right: 0;
    mix-blend-mode: multiply;
  }
}