@import "src/assets/sass/var";

.days {
  display: flex;
  overflow: hidden;
  width: calc(100% - 100px);
  margin-left: 100px;

  .border-today {
    display: none;
  }

  .non-working-hours-start {
    display: inherit;
    position: absolute;
    top: 60px;
    background: $background-border-table;
    width: 100%;
    z-index: 1;
    border-radius: 0 0 16px 16px;

    &:hover {
      cursor: not-allowed;
    }
  }

  .non-working-hours-end {
    display: inherit;
    position: absolute;
    background: $background-border-table;
    width: 100%;
    z-index: 1;
    border-radius: 16px 16px 0 0;

    &:hover {
      cursor: not-allowed;
    }
  }

  .lunch {
    display: inherit;
    position: absolute;
    background: $background-border-table;
    width: 100%;
    border-radius: 16px;

    &:hover {
      cursor: not-allowed;
    }
  }

  // just day
  .day {
    border-top: 0;
    position: relative;
    float: left;
    flex-grow: 1;
    color: #000000;

    .dayDate {
      text-align: center;
      height: 60px;
      margin-bottom: 20px;
      padding-top: 5px;
      background: #F6F6F8;
      border-top: 1px solid $border-table-time-line;
      border-bottom: 1px solid $border-table-time-line;
      display: flex;
      flex-direction: column;

      &_weekDay {
        font-weight: 600;
        display: block;
        color: #2A2A30;
        font-size: 16px;
        line-height: 24px;
      }

      &_date {
        font-size: 14px;
        line-height: 21px;
        color: $paragraph-text-background;
      }

      &_free-day-block {
        margin-top: -20px;
        border-left: 1px solid $background-border-table;

        &_free-day {
          opacity: 0;
          padding: 10px 0 0 30px;
          display: block;
          width: 100%;
          height: 180px;

          .free-time-block {
            margin-top: -10px;
            margin-left: -30px;
            opacity: 0;

            .time-elem {
              height: 15px;
              opacity: 0;
            }
          }
        }

        &_free-day:hover {
          cursor: pointer;
          opacity: 1;

          .free-time-block {

            &:hover {
              opacity: 1;
              border: 0;
            }

            .time-elem {
              height: 15px;
              opacity: 0;

              &:hover {
                background: $day-color;
                opacity: 1;
                display: flex;
                justify-content: end;
                align-items: center;

                .content {
                  font-size: 12px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }


    }

    .day-event {
      display: inherit;
      border-left: 2px solid black;
      position: absolute;
      width: calc(100% - 10px);
      padding: 10px;
      border-radius: 0 12px 12px 0;
      box-sizing: border-box;
      color: #2A2A30;
      font-size: 12px;
      z-index: 1;
      transition: all 0.3s ease;
      background: #FFFFFF;

      .event {
        transition: all 0.3s ease;
        padding: 10px;
        border-radius: 0 12px 12px 0;
        margin: -10px;

        .reason {
          font-weight: bold;
          display: block;
          transition: all 0.5s ease;
        }

        .start {
          display: block;
          font-weight: normal;

          .waiting-room {
            margin-left: 10px;
          }

          .waiting-room:hover {
            cursor: pointer;
            border-bottom: 2px solid $day-color;
          }

          .status {
            margin-left: 10px;
          }
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;

          .photo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }
        }

        .waiting-room {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
          border-bottom: 1px solid $paragraph-text-background;
        }

        .status {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }

        .img-ok {
          width: 22px;
          height: 22px;
          border: 2px solid $day-color;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }

        .img-Close {
          width: 22px;
          height: 22px;
          border: 2px solid #fd510dc9;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }

        .waiting-room {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;

          .img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }
        }
      }

      .event:hover {
        padding-top: -10px !important;
      }
    }

    .day-event:hover {
      cursor: pointer;

      .edd-button,
      .edd-button-small {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    }

    .day-several-events {
      display: none;
      border-left: 2px solid black;
      position: absolute;
      width: calc(100% - 10px);
      padding: 10px;
      border-radius: 0 12px 12px 0;
      box-sizing: border-box;
      color: #2A2A30;
      font-size: 12px;
      z-index: 1;
      list-style: none;
      transition: all 0.3s ease;
      background: #FFFFFF;

      .event {
        transition: all 0.3s ease;
        padding: 10px;
        border-radius: 0 12px 12px 0;
        margin: -10px;

        &:first-child {
          display: inherit;
        }

        .reason {
          font-weight: bold;
          display: block;
          transition: all 0.5s ease;
        }

        .start {
          display: block;
          font-weight: normal;

          .waiting-room {
            margin-left: 10px;
          }

          .waiting-room:hover {
            cursor: pointer;
            border-bottom: 2px solid $day-color;
          }
        }

        .client {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor {
          box-sizing: border-box;
          overflow: hidden;
        }

        .doctor-photo {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
          display: none;

          .photo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: none;
          }
        }

        .waiting-room {
          margin-left: 85%;
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }

        .img-ok {
          width: 22px;
          height: 22px;
          border: 2px solid $day-color;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }

        .img-Close {
          width: 22px;
          height: 22px;
          border: 2px solid #fd510dc9;
          border-radius: 15%;
          padding: 3px;
          margin-left: 10px;
        }
      }
    }

    .day-several-events:hover {
      cursor: pointer;
      border-bottom: 1px solid $day-color;
      border-top: 1px solid $day-color;
      border-right: 1px solid $day-color;

      .edd-button,
      .edd-button-small {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .amount-events {
      position: absolute;
      top: -10%;
      right: -10px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .amount-content {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #49CD6E;
      }
    }

    .edd-button,
    {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      right: -25px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: $day-color;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      display: none;

      &_title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #FFFFFF;
      }
    }
  }

  // date now //////////////////
  .dayNow {
    border-top: 1px solid $background-border-table;

    .border-today {
      display: inherit;
      position: absolute;
      width: calc(100% - 2px);
      margin-left: 1px;
      margin-top: -1px;
      height: 100%;
      border: 1px solid rgba(73, 205, 110, 0.7);
      border-radius: 16px;
    }

    .non-working-hours-start.non-working-hours-start {
      display: inherit;
      position: absolute;
      top: 60px;
      background: $background-border-table;
      margin-top: -2px;
      width: 100%;
      z-index: 1;
      border-radius: 0 0 16px 16px;

      &:hover {
        cursor: not-allowed;
      }
    }

    .non-working-hours-end.non-working-hours-end {
      display: inherit;
      position: absolute;
      background: $background-border-table;
      width: calc(100%);
      z-index: 1;
      border-radius: 16px 16px 0 0;

      &:hover {
        cursor: not-allowed;
      }
    }

    .lunch.lunch {
      display: inherit;
      position: absolute;
      background: $background-border-table;
      width: calc(100% - 4px);
      margin-left: 2px;
      border-radius: 16px;

      &:hover {
        cursor: not-allowed;
      }
    }

    .dayDateNow {
      height: 58px;
      border: 1px solid rgba(73, 205, 110, 0.7);
      border-radius: 16px;
      width: calc(100% - 2px);
      margin-left: 1px;
    }

    .dayDate {

      &_weekDay {
      }

      &_date {
      }

      &_free-day-block {
        margin-top: -20px;
        position: relative;
        border: 1px solid $background-border-table;

        &_free-day {
          z-index: 10000000;

          .free-time-block {

            .time-elem {
            }
          }
        }

        &_free-day:hover {

          .free-time-block {
            &:hover {
            }

            .time-elem {

              &:hover {

                .content {

                }
              }
            }
          }

          &:nth-last-child(-n+1) {
            .free-time-block {
              &:hover {
                opacity: 1;
                border: 0;
              }

              .time-elem {
                height: 15px;
                opacity: 0;

                &:hover {
                  background: $day-color;
                  opacity: 1;
                  display: flex;
                  justify-content: end;
                  align-items: center;

                  .content {
                    font-size: 12px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }

        }
      }
    }

    .holiday-wrap {
      display: inherit;
      position: absolute;
      width: calc(100%);
      box-sizing: border-box;
      z-index: 1;
      background: transparent;
      overflow: hidden;

      &:hover {
        cursor: not-allowed;
      }

      .day-holiday {
        border-left: 32px solid #a8b4b1;
        position: absolute;
        padding: 10px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        z-index: 1;
        font-size: 14px;
        background: $background-border-table;
        margin-left: 3px;
        width: calc(100% - 15px);

        .holiday-name {
          position: absolute;
          top: 50%;
          left: -16px;
          max-height: 30px;
          transform: translate(-50%, -50%) rotate(270deg);
          font-weight: bold;
          color: white;
          font-size: 20px;
          text-align: center;
          overflow: hidden;
          margin-bottom: 5px;
          margin-top: 5px;


          @media screen and (max-width: 1650px) {
            width: 200%;
          }

          @media screen and (max-width: 1100px) {
            width: 300%;
          }

          @media screen and (max-width: 950px) {
            width: 500%;
          }
        }

        .holiday-el {
          background: linear-gradient(45deg, #a8b4b1 25%, transparent 0, transparent 50%, #a8b4b1 0, #a8b4b1 75%, transparent 0);
          background-size: 50px 50px;
          opacity: 1;
          background-position: center;
          margin: -10px;
          border-radius: 0 12px 12px 0;
          border-top: 1px solid #D6D8E7;
          border-right: 1px solid #D6D8E7;
          border-bottom: 1px solid #D6D8E7;
        }
      }
    }

    .day-event {
      margin-left: 3px;
      width: calc(100% - 15px);

      .event {

        .reason {
        }

        .start {

          .waiting-room {
          }

          .status {
          }

          .waiting-room:hover {
          }
        }

        .client {
        }

        .doctor {
        }

        .doctor-photo {

          .photo {
          }
        }

        .waiting-room {
        }

        .status {
        }

        .img-ok {
        }

        .img-Close {
        }
      }
    }

    .day-event:hover {

      .edd-button,
      .edd-button-small {
      }
    }

    .day-several-events {
      margin-left: 3px;
      width: calc(100% - 15px);
      transition: all 0.3s ease;

      &:first-child {
      }

      .event {

        .reason {
        }

        .start {

          .waiting-room {
          }

          .status {
            display: none;
          }

          .waiting-room:hover {
          }
        }

        .client {
        }

        .doctor {

        }

        .doctor-photo {

          .photo {
          }
        }

        .waiting-room {
        }

        .status {
          display: none;
        }

        .img-ok {
        }

        .img-Close {
        }
      }
    }

    .day-several-events:hover {

      .edd-button,
      .edd-button-small {
      }
    }

    .amount-events {

      .amount-content {
      }
    }

    .edd-button {

      &_title {
      }
    }
  }

  //holiday /////////////////////
  .dayHoliday {

    .border-today {
      display: none;
    }

    .non-working-hours-start {
      display: inherit;
      position: absolute;
      top: 60px;
      background: $background-border-table;
      width: 100%;
      z-index: 1;
      border-radius: 0 0 16px 16px;

      &:hover {
        cursor: not-allowed;
      }
    }

    .non-working-hours-end {
      display: inherit;
      position: absolute;
      background: $background-border-table;
      width: 100%;
      z-index: 1;
      border-radius: 16px 16px 0 0;

      &:hover {
        cursor: not-allowed;
      }
    }

    .lunch {
      display: inherit;
      position: absolute;
      background: $background-border-table;
      width: 100%;
      z-index: 1;
      border-radius: 16px;

      &:hover {
        cursor: not-allowed;
      }
    }

    .dayDateHoliday {
    }

    .dayDate {

      &_weekDay {
      }

      &_date {
      }

      &_free-day-block {

        &_free-day {

          .free-time-block {

            .time-elem {
            }
          }
        }

        &_free-day:hover {

          .free-time-block {

            &:hover {
            }

            .time-elem {

              &:hover {

                .content {
                }
              }
            }
          }
        }
      }
    }

    .holiday-wrap {
      display: inherit;
      position: absolute;
      width: calc(100%);
      box-sizing: border-box;
      z-index: 1;
      background: transparent;
      overflow: hidden;

      &:hover {
        cursor: not-allowed;
      }

      .day-holiday {
        border-left: 32px solid #a8b4b1;
        position: absolute;
        width: calc(100% - 10px);
        padding: 10px;
        border-radius: 0 12px 12px 0;
        box-sizing: border-box;
        color: #2A2A30;
        z-index: 1;
        font-size: 14px;
        background: $background-border-table;

        .holiday-name {
          position: absolute;
          top: 50%;
          left: -16px;
          max-height: 30px;
          transform: translate(-50%, -50%) rotate(270deg);
          font-weight: bold;
          color: white;
          font-size: 20px;
          text-align: center;
          overflow: hidden;
          margin-bottom: 5px;
          margin-top: 5px;


          @media screen and (max-width: 1650px) {
            width: 200%;
          }

          @media screen and (max-width: 1100px) {
            width: 300%;
          }

          @media screen and (max-width: 950px) {
            width: 500%;
          }
        }

        .holiday-el {
          background: linear-gradient(45deg, #a8b4b1 25%, transparent 0, transparent 50%, #a8b4b1 0, #a8b4b1 75%, transparent 0);
          background-size: 50px 50px;
          opacity: 1;
          background-position: center;
          margin: -10px;
          border-radius: 0 12px 12px 0;
          border-top: 1px solid #D6D8E7;
          border-right: 1px solid #D6D8E7;
          border-bottom: 1px solid #D6D8E7;
        }
      }
    }

    .day-event {

      .event {

        .reason {
        }

        .start {

          .waiting-room {
            border-bottom: 1px solid $paragraph-text-background;
          }

          .waiting-room:hover {
          }

          .status {
          }
        }

        .client {
        }

        .doctor {
        }

        .doctor-photo {

          .photo {
          }
        }

        .waiting-room {
        }

        .status {
        }

        .img-ok {
        }

        .img-Close {
        }
      }
    }

    .day-event:hover {
      cursor: pointer;
      border-bottom: 1px solid $day-color;
      border-top: 1px solid $day-color;
      border-right: 1px solid $day-color;

      .edd-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .day-several-events {

      &:first-child {
      }

      .event {

        .reason {
        }

        .start {

          .waiting-room {
          }

          .waiting-room:hover {
          }

          .status {
            display: none;
          }
        }

        .client {
        }

        .doctor {
        }

        .doctor-photo {

          .photo {
          }
        }

        .waiting-room {
          border-bottom: 1px solid $paragraph-text-background;
        }

        .status {
          display: none;
        }

        .img-ok {
        }

        .img-Close {
        }
      }
    }

    .day-several-events:hover {

      .edd-button,
      .edd-button-small {
      }
    }

    .amount-events {

      .amount-content {
      }
    }

    .edd-button {

      &_title {
      }
    }
  }
}
