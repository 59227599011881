.timePicker {
  height: 60px;

  .react-datepicker {
    &-popper[data-placement^=top] {
      margin-bottom: -35px !important;
    }

    &-popper[data-placement^=bottom] {
      padding-top: 50px !important;
    }

    &-wrapper {
      height: 60px !important;

    }

    &__input-container {
      height: 60px !important;
    }

    &-popper {
    }

    &__time-box {
      display: flex !important;
      justify-content: center !important;
    }

    &__time-list {
      &-item {
        margin-left: 17px !important;
        width: 50px !important;
        padding: 6px 10px !important;

        &--selected {
          background-color: #49CD6E !important;
          border-radius: 8px !important;
          width: 50px;
        }
      }

      &-item--disabled {
        display: none;
      }

      &-item:hover {
        border-radius: 8px !important;

      }
    }

    &--time {

      &-only {
        border: none !important;
        border-radius: 13px !important;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;
        top: -35px
      }
    }

    &__triangle {
      display: none;
    }

    &__header {
      border: none !important;
      background-color: white !important;

      &--time {
        &-only {
        }
      }
    }
  }

}