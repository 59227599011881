@import "../../assets/sass/var";

* {
  box-sizing: border-box;
}

.fieldW {
  margin-bottom: 40px;
  position: relative;

  &_focus {
    .fieldW {
      &__label {
        top: 0px;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .fieldW {
      &__label {
        transition: 0s;
      }
    }
  }

  &_blur {
    .fieldW {
      &__label {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .fieldW {
      &__label {}
    }
  }

  &_focus {
    .fieldW {
      &__labels {
        top: 0;
        position: absolute;
        transition: 0s;
        font-size: $label-font-size;
        letter-spacing: 0.4px;
      }
    }
  }

  &_unfocus {
    .fieldW {
      &__labels {
        transition: 0s;
      }
    }
  }

  &_blur {
    .fieldW {
      &__labels {
        font-size: 16px;
      }
    }
  }

  &_unblur {
    .fieldW {
      &__labels {}
    }
  }

  &__input {
    height: 64px;
    width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $general-error-text-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0;
  }

  &__input[type="date"]::-webkit-inner-spin-button,
  &__input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }


  &__label {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__imgNone {
    display: none !important;

  }

  &__img {
    z-index: 0;
    position: absolute;
    float: right;
    right: 10px;
    top: 10px;
    padding: 10px;

  }


  &__labels {
    position: absolute;
    top: 12px;
    left: 18px;
    margin: 8px;
    letter-spacing: 0.75px;
    transition: 0s;
    font-size: 16px;
    color: $paragraph-text-background;
  }

  &__select {
    min-height: 64px;
    width: 100%;
    border: 2px solid #D6D8E7;
    letter-spacing: 0.75px;
    border-radius: 16px;
    background-color: $btn-text-color;
    font-size: $general-error-text-size;
    color: $btn-background;
    padding: 21px 24px;
    padding-bottom: 0;
  }

  &__option {
    margin-top: 10px;
  }

  &__clear :hover {
    opacity: 0.7;
  }

  &__clear {
    color: $btn-clear;
    position: absolute;
    top: 20px;
    right: 29px;
    z-index: 1;
  }

  &_timePicker {
    .fieldW {
      &__label {

        position: absolute;
        top: 0;
        left: 18px;
        margin: 8px;
        letter-spacing: 0.4px;
        transition: 0s;
        font-size: 14px;
        color: $paragraph-text-background;
      }

      &__input::placeholder {
        color: rgb(65, 65, 65);
        font-family: 'PoppinsRegular';
        font-size: 14px;
      }

      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      &__input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input {
        background: white url(../../assets/img/icons/times.svg) no-repeat;
        background-position: right 20px bottom 16px;
      }

      &__input:focus {
        outline: none!important;
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }
      &__input:disabled {
        opacity: 0.4;
        border: 2px solid $background-disabled;
        height: 64px;
        background: $background-disabled url(../../assets/img/icons/times_disabled.svg) no-repeat;
        background-position: right 20px bottom 16px;
      }
    }
  }

  &_timePickerW {
    .fieldW {
      &__label {
        position: absolute;
        top: 0;
        left: 18px;
        margin: 8px;
        letter-spacing: 0.4px;
        transition: 0s;
        font-size: 14px;
        color: $paragraph-text-background;
      }

      &__input::placeholder {
        color: rgb(65, 65, 65);
        font-family: 'PoppinsRegular';
        font-size: 14px;
      }

      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      &__input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input {
        background: white url(../../assets/img/icons/times.svg) no-repeat;
        background-position: right 20px bottom 16px;
      }

      &__input:focus {
        outline: none!important;
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }


  &_datePicker {
    .fieldW {
      &__label {
        position: absolute;
        top: 0px;
        left: 18px;
        margin: 8px;
        letter-spacing: 0.4px;
        transition: 0s;
        font-size: 14px;
        color: $paragraph-text-background;
      }

      &__input::placeholder {
        color: rgb(65, 65, 65);
        font-family: 'PoppinsRegular';
        font-size: 14px;
      }

      &__input::-webkit-calendar-picker-indicator {
        opacity: 0;
      }

      &__input::-webkit-calendar-picker-indicator {
        display: none;
      }

      &__input {
        background: white url(../../assets/img/icons/calendar.svg) no-repeat;
        background-position: right 20px bottom 16px;
      }


      &__input:focus {
        outline: none!important;
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }
    }
  }


  &_disabled {
    .fieldW {
      &__input {
        background: white url(../../assets/img/icons/times.svg) no-repeat;
        background-position: right 20px bottom 16px;
        background-color: #ebebeb;
        border: 2px solid #F6F6F8;
        color: #737484;
        cursor: none;
      }

      &__input:hover {
        background-color: #ebebeb;
        border: 2px solid #F6F6F8;
        color: #737484;
        cursor: not-allowed;

      }

      &__label {
        cursor: not-allowed;
      }
    }
  }


  &_datePickerNoterror {
    .fieldW {
      &__input {
        background-color: #00ba88;
      }
    }
  }


  &_active {
    .fieldW {
      &__input:focus {
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__input:hover {
        border: 2px solid $border-field-focus;
        height: 64px;
        background-color: $btn-text-color;
      }

      &__help-text {
        font-size: 14px;
        letter-spacing: $help-text-spacing;
        color: #737484;
      }
    }
  }

  &_caption {
    .fieldW {
      &__input {
        margin-bottom: 9px;
      }

      &__help-text {
        font-size: $label-font-size;
        letter-spacing: $help-text-spacing;
        color: $paragraph-text-background;
      }

      &__clear {
        margin-top: -75px;
      }
    }
  }


  &_error {
    .fieldW {
      &__input {
        background-color: $input-color-error;
        border: 2px solid $input-border-error;
      }

      &__label {
        color: $input-color-help-text;
      }

      &__clear {
        margin-top: -69px;
        color: $input-border-error;
      }

      &__help-text {
        letter-spacing: $help-text-spacing;
        font-size: $label-font-size;
        color: $input-color-help-text;
        padding-top: -20px;
      }
    }
  }
}