$btn-background: #2a2a30;
$btn-text-color: #ffffff;
$btn-clear: #14142B;
$btn-color-gray: #eff0f6;
$btn-hover-blue: #2694da;
$btn-border: #D6D8E7;
$main-background: #f6f6f8;
$main-success-background: #e5e5e5;
$background-even: #f2f2f5;
$background-success: #e1e1e1;
$background-border-table: #E6E6E6;
$background-checked: #49cd6e71;
$background-disabled: #d6d8e793;
$background-not-disabled: #dfdddd5d;
$input-color-error: #fff3f8;
$input-border-error: #ed2e7e;
$input-color-help-text: #c30052;
$text-weekend-color: #ae1412;
$text-todayDate-color: rgba(6, 235, 0, 0.11);
$text-holidayDate-color: rgba(242, 20, 0, 0.11);
$border-table: #ddd;
$border-table-time-line: #E7E6E6;
$paragraph-text-background: #737484;
$day-color: #49cd6e;
$logo-two-color: #005fab;
$label-font-size: 14px;
$general-error-text-size: 16px;
$help-text-spacing: 0.25px;
$sidebar-collapse-time: all 0.3s ease;
$border-field-focus: #2694da;
$border-radius-popup: 24px;
$background-popup-window: rgba(0, 0, 0, 0.199);