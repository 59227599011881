.datePicker {
  height: 60px;

  .react-datepicker {
    border-radius: 13px !important;
    border: none !important;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;

    &-wrapper {
      height: 60px !important;

    }

    &__input-container {
      height: 60px !important;
    }

    &__month-container {

      padding: 10px !important;
    }

    &__year {
      &-dropdown {
        &--scrollable {
          background-color: white !important;
          width: 44% !important;
          left: 28% !important;
          border-radius: 8px !important;
        }
      }
    }


    &-popper[data-placement^=bottom] {
      margin-top: 8px !important;
    }


    &-popper {
    }

    &__triangle {
      display: none !important;
      left: -8px !important;
    }

    &__triangle::after {
      border-bottom-color: white !important;
    }

    &__triangle::before {
      border-bottom-color: white !important;
    }

    &__header {
      background-color: white !important;
      border: none !important;
    }

    &__current-month {
      &--hasYearDropdown {
      }
    }

    &__day {
      &--outside-month {
        color: gray !important;
      }

      &--weekend {
        color: red !important;
      }

      &-name {
        font-weight: bold;
        font-size: 14px;
      }

      &-names {
        margin-top: 10px;
      }

      &--today {
        border-radius: 7px !important;
        background-color: white !important;
        color: black !important;
      }

      &--selected {
        background-color: #49CD6E !important;
        color: white !important;
        font-weight: bold !important;
      }

      &--keyboard-selected {
        background-color: #49CD6E !important;
        color: white !important;
        font-weight: bold !important;
      }
    }

    &__navigation {
      margin-top: 10px !important;

      &-icon::before {
        border-width: 1.5px 1.5px 0 0 !important;
      }

      &--years-previous {
        content: 'mordldkdkkde';
      }
    }
  }
}

