@import "../../../assets/sass/var";

.full-width {
  margin-left: -290px;
}

.template-order {
  padding-bottom: 40px;
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__title {
    margin-top: -40px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a30;
    line-height: 21px;
    letter-spacing: 0.5px;
  }

  &__table {
    margin-top: 20px;
    font-size: 18px;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 40px;
    display: table;
    box-sizing: border-box;
    text-indent: initial;
    background: #FFFFFF;
    border: 1px solid #E7E6E6;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    padding: 36px;
    border-collapse: separate;
    border-spacing: 4px;

    .tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

      .tr {
        padding: 5px 5px;
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;

        .th {
          color: black;
        }

        .th-color {
          font-weight: 400;
          text-align: center;
          font-size: 14px;
          color: white;
          height: 32px;
          border-radius: 10px;
          margin: 0;
          background: #49CD6E;
          cursor: default;
        }

        .td {
          font-weight: bold;
          text-align: center;
          font-size: 14px;
          color: #2a2a30;
          margin: 0;
          background: white;
        }

        .td-color {
          font-size: 14px;
          background-color: #49CD6E;
          border-radius: 10px;
          padding: 0;
          margin: 0;
          font-weight: 400;
          color: white;
          text-align: center;
          cursor: default;
        }

        .circle {
          display: flex;
          justify-content: center;
          margin-top: 14px;
        }

        .td-middle {
          width: 8px;
          height: 8px;
          background: #EFF0F6;
          border-radius: 5px;
          display: flex;
        }
      }
    }
  }

  .wrapper {
    .page {
      &__header {
        margin-left: 30px;
        margin-bottom: -25px;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 66px;
        letter-spacing: 1px;
        color: $btn-background;
      }

      &__text {
        font-size: 14px;
        color: #414141;
        display: block;
        overflow: hidden;
        box-sizing: border-box;
        padding: 35px;
      }
    }
  }

  .forms {
    margin: 30px 30px 0px;

    .info {
      &__text {
        margin-top: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5;
        color: $btn-background;
        margin-bottom: 20px;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        margin-top: 10px;
      }
    }

    .setting {
      &__text {
        font-size: 18px;
        font-weight: bold;
        color: #2a2a30;
        line-height: 21px;
        letter-spacing: 0.5px;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        margin-top: 10px;
      }
    }

    @media (min-width: 1300px) {
      width: 950px;
    }
  }

  .general-error {
    letter-spacing: $help-text-spacing;
    font-size: $general-error-text-size;
    color: $input-color-help-text;
    text-align: center;
  }
}