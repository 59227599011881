.notification {
  margin: 35px 5px 0 25px;

  .menu {
    width: 385px;
    background-color: white;
    position: relative;
    z-index: 1000;
    border-radius: 20px;
    overflow: scroll;

    &__header {
      padding: 16px 0 16px 28px;
      height: 56px;

      &-name {
        color: #2A2A30;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.25px;
      }
    }

    &__line {
      border: 0.5px solid #E7E6E6;
      margin-top: -4px;
    }

    &__today {
      padding: 10px 0 16px 28px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: #8C8C8C;
    }

    &__event {
      display: flex;
      background: rgba(73, 205, 110, 0.15);
      height: 104px;

      &-img {
        width: 44px;
        height: 44px;
        margin-left: 28px;
        margin-right: 12px;
        margin-top: 17px;
      }

      &-info {
        font-size: 14px;
        margin: 18px 12px 44px 5px;
        font-weight: normal;
        line-height: 21px;
      }

      &-time {
        position: absolute;
        margin: 69px 10px 0px 84px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #8C8C8C;
      }

      &-line {
        margin: 0px;
        border: 0.5px solid #E7E6E6;
      }
    }

    &__week {
      padding: 16px 0px 16px 28px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: #8C8C8C;
    }
  }

  .app {
    &__text {
      width: 100%;
    }

    &__collapse {
      top: 77px;
      right: 27px;
      display: block;
      position: absolute;
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
      background-color: transparent;

      &--gradient {
        .app__content {
          border-color: transparent;
        }

        &:after {
          pointer-events: none;
          transition: opacity 300ms;
          will-change: opacity;
          opacity: 1;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(transparent 50%, white);
        }
      }

      &--active {
        &:after {
          opacity: 0;
        }
      }
    }

    &__content {
    }

    &__toggle {
      width: 100%;
      transition: background-color 300ms;
      margin-top: 5px;
      padding-right: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
      cursor: pointer;

      .cross {
        width: 20px;
        height: 20px;
        position: relative;
        right: 5px;
        top: 50%;
        transform: translateX(75px);

        &::after,
        &::before {
          content: "";
          display: block;
          position: absolute;
          height: 2px;
          background-color: black;
          width: 15px;
          top: 50%;
          transition: all .2s ease-in-out;
          right: 0;
        }

        &::after {
          transform: rotate(90deg);
          left: 5px;
        }

        &.active {
          &::before {
            transform: rotate(-48deg);
            right: 3px;
            width: 9px;

          }

          &::after {
            width: 9px;
            transform: rotate(45deg);
            left: 2px;

          }
        }
      }

      .arrow {
        width: 4px;
        height: 4px;
        display: inline-block;
        position: relative;
        top: -10px;
        left: 5px;
      }

      .arrow span {
        top: 8px;
        position: absolute;
        width: 10px;
        height: 1.6px;
        background-color: black;
        display: inline-block;
        transition: all 0.2s ease;
      }

      .arrow span:first-of-type {
        left: 0;
        transform: rotate(45deg);
      }

      .arrow span:last-of-type {
        right: 0;
        transform: rotate(-45deg);
      }

      .arrow.active span:first-of-type {
        transform: rotate(-45deg);
      }

      .arrow.active span:last-of-type {
        transform: rotate(45deg);
      }

      .down {
        transform: rotate(0deg);
        transition: 0.3s;
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .up {
        transform: rotate(-180deg);
        transition: 0.3s;
        align-items: flex-end;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .filter-title-name {
        width: 71.5%;
      }

      &--active {
      }
    }
  }


}