@import "../../../assets/sass/var";

@keyframes blink {
  0% {
    border: 1.5px solid #49cd6e;
    border-radius: 25px;
  }

  50% {
    border: 1.5px solid transparent;
    border-radius: 25px;
  }

  100% {
    border: 1.5px solid #49cd6e;
    border-radius: 25px;
  }
}

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.containerNone {
  display: none;
}

.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $background-popup-window;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;


  .waitingRoomAlert {
    height: 80vh;
    overflow: auto;
    margin: 0;
    border-radius: $border-radius-popup;
    background: $main-background;
    width: 942px;
    grid-area: content;

    &__btnBlock {
      margin-top: -9px;
      margin-right: 25px;
      float: right;

      .button-close-component {
        position: absolute;
      }
    }

    &__text {
      margin-left: 30px;
      margin-bottom: 25px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 1px;
      color: $btn-background;
    }


    &__title {
      margin-top: 11px;
      margin-left: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    .title {
      margin-left: 30px;
      font-style: normal;
      width: 100%;
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    .preloader-wrap {
      position: absolute;
      left: 50%;


      .preloader {
        &__event {
          background: none;
          &-img {
          }
        }
      }
    }

    &__table {
      table-layout: fixed;
      border: 0;
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      margin-top: 20px;

      .thead {
        .th {
          padding: 30px 0;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #737484;
          border: solid $background-border-table;
          border-width: 1px 0;
          width: 100%;
        }

        .th-name {
          width: 120%;
        }

        .th-reason {
          width: 120%;
        }

        .th-war {
          width: 100%;
        }

        .th-btn {
          width: 60%;
        }
      }

      .tbody {


        .tr {
          background: $background-even;

          &:nth-of-type(even) {
            background: $main-background;
          }

          &:nth-last-child(-n+1) {
            border-bottom: 1pt solid $background-border-table;
          }

          .td {
            padding: 20px 10px;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.25px;
            color: #2A2A30;
            width: 100%;
          }


          .td-name {
            padding: 20px 30px;
            text-align: left;
            font-style: normal;
            font-weight: normal;
            width: 120%;
          }

          .td-btns {
            padding: 20px 20px;
            width: 60%;

            .btns {
              display: flex;
              justify-content: end;
              width: 100%;
              padding-right: 20px;

              .wrap-img-doc {
                cursor: pointer;

                .img-doc {
                  width: 25px;
                  height: 25px;
                }
              }

              .wrap-img-not {
                cursor: pointer;
                margin-left: 20px;

                .img-not {
                  width: 16px;
                  height: 16px;
                  margin-top: 5px;
                }
              }

              .wrap-img-done {
                cursor: pointer;

                .img-done {
                  width: 25px;
                  height: 25px;
                }
              }

              .wrap-img-edit {
                cursor: pointer;
                margin-left: 20px;

                .img-edit {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }

          .td-color {
            border-left: 2px solid black;
            padding: 10px;
            box-sizing: border-box;
            color: #2A2A30;
            z-index: 1;
            list-style: none;
            word-break: break-word;
            width: 120%;
          }

          .button {
            margin-left: 15px;
          }

          .borderNone {
            display: none;
          }
        }

        .active {
          position: relative;

          .td-btns {
            .wrap-img-done, .wrap-img-edit, .wrap-img-doc, .wrap-img-not {
              z-index: 2;
            }
          }

          .borderDoctor {
            display: inherit;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 1.5px solid transparent;
            animation: 1s 4 blink;
            z-index: 1;
          }
        }

        .date-waiting {
          display: flex;
          flex-direction: column;
        }

        .time {
          white-space: nowrap;
        }

        .tr:hover {
          background: rgba(219, 217, 217, 0.459);
        }
      }
    }
  }

}