@import "src/assets/sass/var";

.full-width {
  margin-left: -290px;
}

.add-employee {
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
    margin-left: 30px;
    margin-bottom: 8px;
  }

&__inputs-label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: $btn-background;
  padding-bottom: 20px;
}

&__inputs-info {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-column-gap: 20px;
  margin-top: 10px;
}

&__inputs-setting {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-column-gap: 20px;
  margin-top: 10px;
}

  &__form {
    margin: 0 30px 40px;
    .fieldset {
      display: block;
      width: 100%;
      overflow: hidden;
      clear: both;
      .field {
        width: 50%;
        float: left;
        padding-right: 25px;
      }
    }
    
    .general-error {
      letter-spacing: $help-text-spacing;
      font-size: $general-error-text-size;
      color: $input-color-help-text;
      text-align: center;
    }

    @media (min-width: 1300px) {
      width: 950px;
    }
  }
}