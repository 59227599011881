.wrap {

  .arrow {
    width: 4px;
    height: 4px;
    display: inline-block;
    position: relative;
    left: -36px;
  }

  .arrow span {
    top: 8px;
    left: -8px;
    position: absolute;
    width: 13px;
    height: 1.8px;
    background-color: black;
    display: inline-block;
    transition: all 0.2s ease;
  }

  .arrow span:first-of-type {
    left: 0;
    transform: rotate(45deg);
  }

  .arrow span:last-of-type {
    right: 0;
    transform: rotate(-45deg);
  }

  .arrow.active span:first-of-type {
    transform: rotate(-45deg);
  }

  .arrow.active span:last-of-type {
    transform: rotate(45deg);
  }

  .up {
    transform: rotate(180deg);
    transition: 0.3s;
    margin-right: 8px;
    margin-top: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;

  }

  .down {
    transform: rotate(0deg);
    transition: 0.3s;
    margin-right: 8px;
    margin-top: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}