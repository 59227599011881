@import "../../../assets/sass/var";

.full-width {
  margin-left: -290px;
}

.add-event {
  padding-bottom: 40px;
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 66px;
    letter-spacing: 1px;
    color: $btn-background;
    margin-left: 30px;
    margin-bottom: 8px;
  }

  .name {
    margin-left: 30px;
    font-size: 20px;
    font-weight: bold;
  }

&__inputs-label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: $btn-background;
  padding-bottom: 20px;
}

&__inputs-info {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-column-gap: 20px;
  margin-top: 10px;
  margin-bottom: 20px;

  .client-button-wrapp {

    .add_client {
      display: inline-block;
      margin-top: 19px;
      width: 25px;
      height: 25px;

      &:hover {
        cursor: pointer;
      }
    }
    .edit_client {
      display: inline-block;
      margin-top: 19px;
      margin-left: 20px;
      width: 25px;
      height: 25px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

&__inputs-setting {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-column-gap: 20px;
  margin-top: 10px;
  margin-bottom: 60px;
}

  &__form {
    margin: 20px 40px 0;
    padding-bottom: 60px;
    min-height: 100vh;
    .fieldset {
      display: block;
      width: 100%;
      overflow: hidden;
      clear: both;
      .field {
        width: 50%;
        float: left;
        padding-right: 25px;
      }
    }

    .general-error {
      letter-spacing: $help-text-spacing;
      font-size: 20px;
      color: $input-color-help-text;
      text-align: center;
      z-index: 100000;
    }

    @media (min-width: 1300px) {
      width: 950px;
    }
  }
  
  .general-error {
    letter-spacing: $help-text-spacing;
    font-size: 20px;
    color: $input-color-help-text;
    text-align: center;
    z-index: 100000;
  }
}