@import "src/assets/sass/var";

* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

.orders {
  grid-area: content;
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;
  padding-bottom: 20px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;

    &__title {
      margin-left: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background;
    }

    &__add {
      font-weight: bold;
      font-size: 16px;
      margin-left: 40px;
    }
  }

  &__table {
    position: relative;
    table-layout: fixed;
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    .thead {
      .th {
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #737484;
        border: solid $background-border-table;
        border-width: 1px 0;
      }

      .th-options {
        width: 190px;
        padding: 30px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #737484;
        border: solid $background-border-table;
        border-width: 1px 0;

      }
    }

    .tbody {
      .tr {
        background: $background-even;

        &:nth-of-type(even) {
          background: $main-background;
        }

        &:nth-last-child(-n+1) {
          border-bottom: 1pt solid $background-border-table;
        }

        .td {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
          word-break: break-word;
        }

        .ex {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100px;
          margin-top: 33px;
          z-index: 1000;
          right: 2.2%;
          padding: 6px 16px 16px 16px;
          background: white;
          box-shadow: 0 0 10px rgb(0 0 0 / 10%);
          border-radius: 12px;
          cursor: auto;
          overflow-y: auto;
          transition-duration: 0.2s;
        }

        .ex.closed {
          opacity: 0;
        }

        .ex.transitioning {
          height: 0;
          position: absolute;
        }


        .ex.overlay {
          position: absolute;
        }

        .td-name {
          padding: 20px 30px;
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
          word-break: break-word;
        }

        .td-btns {
          padding: 20px 20px;
          text-align: right;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
        }

        .td-color {
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          border-left: 2px solid black;
          width: calc(100% - 10px);
          padding: 10px;
          box-sizing: border-box;
          color: #2A2A30;
          z-index: 1;
          list-style: none;
          word-break: break-word;
        }

        .button {
          margin-left: 15px;
        }
      }

      .tr:hover {
        background: rgba(219, 217, 217, 0.459);
      }
    }
  }
}

.full-width {
  margin-left: -290px;
}