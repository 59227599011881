@import "src/assets/sass/var";

::-webkit-scrollbar {
  width: 0;
}

.login {
  background-color: #f6f6f8;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: auto;
  .img-wrapper {
    margin: 75px auto;
    width: 300px;
    .img {
      margin: 0;
      width: 300px;
    }
  }

  .wrapper {
    background: $btn-text-color;
    border: 1px solid $background-border-table;
    box-sizing: border-box;
    box-shadow: 7px 7px 20px rgb(0 0 0 / 5%);
    border-radius: 24px;
    width: 500px;
    margin: 0 auto;
    padding: 35px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    .title {
      text-align: center;
      padding: 0;
      margin: 10px 0 20px;
      font-weight: normal;
      font-size: 40px;
      line-height: 66px;
      letter-spacing: 1px;
      color: $btn-background
    }

    .general-error {
      margin-top: -16px;
      letter-spacing: $help-text-spacing;
      font-size: $general-error-text-size;
      color: $input-color-help-text;
      text-align: center;
    }

    .button {
      &_color-green {
        background-color: $day-color;
        color: $btn-text-color;
      }
    }
  }

  .language-block {
    margin-top: 20px;
    display: flex;

    .title {
      margin-top: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.25px;
      color: $paragraph-text-background;
    }

    .lang {
      display: flex;
      width: 50px;
      height: 25px;
      margin: 20px 0 37px 20px;
      position: relative;

      .en {
        width: 25px;
        height: 25px;
        margin-right: 7px;
      }

      .de {
        width: 25px;
        height: 25px;
        margin-right: 7px;
      }

      &__img {
        width: 25px;
        height: 25px;
      }

      &__arrow {
        width: 15px;
        height: 15px;
        margin-top: 4px;
        margin-left: 10px;

      }

      &__menuNone {
        display: none;
      }

      &__menu {
        position: absolute;
        right: 0;
        top: 50px;
        background: white;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        border-radius: 12px;
        width: 150px;

        &-info {
          padding: 0 20px 6px 20px;
          color: #737484;
          font-size: 14px;
          cursor: pointer;
        }

        &-info:hover {

          text-decoration: underline solid #737484;
          color: #737484;
          font-size: 14px;
          cursor: pointer;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width:1200px) {
  .login {
    max-width: 970px;
  }
}

@media screen and (max-width:992px) {
  .login {
    max-width: 750px;
    .img-wrapper {
      margin: 75px auto;
      width: 200px;
      .img {
        width: 200px;
      }
    }

    .wrapper {
      width: 350px;
      .title {
        font-size: 25px;
      }

      .button {
        padding: 10px;
        width: 120px;
      }
    }
  }

}

@media screen and (max-width:767px) {
  ::-webkit-scrollbar {
    width: 0;
  }

  .login {
    max-width: none;
    position: fixed;
  }
}
