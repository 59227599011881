@import "../assets/sass/var";

* {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0;
}

.admin {
  min-width: 100%;
  min-height: 100vh;
  background: $main-background;
  display: grid;
  grid-template-rows: 84px 1fr;
  grid-template-columns: 290px 1fr;
  grid-template-areas:
    'header header'
    'navbar content';
  -webkit-transition: $sidebar-collapse-time;
  -moz-transition: $sidebar-collapse-time;
  -o-transition: $sidebar-collapse-time;
  transition: $sidebar-collapse-time;

  &__common {
    display: flex
  }

  &__left {
    grid-area: navbar;
    border-radius: 0 40px 40px 0;
    background: $btn-text-color;
    width: 288px;
    height: 100vh;
    position: fixed;
    display: block;

    margin-top: -84px;
    -webkit-transition: $sidebar-collapse-time;
    -moz-transition: $sidebar-collapse-time;
    -o-transition: $sidebar-collapse-time;
    transition: $sidebar-collapse-time;
  }

  .left-open-sidebar {
    margin-left: -290px;
  }

  .info {
    &-right {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 20px;
      margin-top: -7px;

    }
  }

  &__header {
    grid-area: header;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    -webkit-transition: $sidebar-collapse-time;
    -moz-transition: $sidebar-collapse-time;
    -o-transition: $sidebar-collapse-time;
    transition: $sidebar-collapse-time;

    &_nav {
      display: block;
      clear: both;
      float: left;
      overflow: hidden;

      &_list {
        padding: 0;
        margin: 0;

        .menu-btn {
          margin: 35.49px 23px 0 30px;
          width: 20px;
          height: 17.5px;
        }

        .logo-terminow {
          width: 120px;
          height: 23.13px;
          margin-right: 97px;
        }

        &_item {
          list-style: none;
          padding: 0;
          float: left;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.25px;

          &-logo {
            position: relative;
            display: block;
            width: 120px;
            margin: 31.43px 127px 50px 15px;

            .img {
              display: block;
              width: 120px;
              height: 23.13px;
            }

            .img-open-sidebar {
              position: fixed;
            }
          }

          &_link {
            text-decoration: none;
            color: $paragraph-text-background;
            display: block;
            padding: 30px 15px 0 15px;

            @media (min-width: 1200px) and (max-width: 1265px) {
              padding: 30px 13px 0 13px;
            }
            @media (min-width: 1140px) and (max-width: 1200px) {
              padding: 30px 10px 0 10px;
            }
            @media (min-width: 250px) and (max-width: 1139px) {
              padding: 30px 5px 0 5px;
            }

            .span:hover {
              border-bottom: 2px solid #49CD6E;
            }
          }

          .first_link {
            padding: 30px 15px 0 30px;

            @media (min-width: 1200px) and (max-width: 1265px) {
              padding: 30px 13px 0 30px;
            }
            @media (min-width: 100px) and (max-width: 1200px) {
              padding: 30px 10px 0 30px;
            }
            @media (min-width: 250px) and (max-width: 1139px) {
              padding: 30px 5px 0 30px;
            }
          }
        }

        .active_link {
          font-weight: bold;

          .span {
            border-bottom: 2px solid #49CD6E;
            color: $btn-background;
          }
        }

      }
    }

    .app {
      &__text {
        width: 100%;
      }

      &__collapse {
        top: 73px;
        right: 116px;
        display: block;
        position: absolute;
        transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
        background-color: transparent;
        filter: drop-shadow(0 0 10px rgb(0 0 0 / 10%));
        z-index: 1000;

        &--gradient {
          .app__content {
            border-color: transparent;
          }

          &:after {
            pointer-events: none;
            transition: opacity 300ms;
            will-change: opacity;
            opacity: 1;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(transparent 50%, white);
          }
        }

        &--active {
          &:after {
            opacity: 0;
          }
        }
      }

      &__content {
      }

      &__toggle {
        transition: background-color 300ms;
        margin-top: 5px;
        padding-right: 16px;
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.3px;
        cursor: pointer;

        .notification {
          @media (min-width: 1000px) and (max-width: 1250px) {
            margin-left: 10px;
          }
        }

        .cross {
          width: 20px;
          height: 20px;
          position: relative;
          right: 5px;
          top: 50%;
          transform: translateX(75px);

          &::after,
          &::before {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            background-color: black;
            width: 15px;
            top: 50%;
            transition: all .2s ease-in-out;
            right: 0;
          }

          &::after {
            transform: rotate(90deg);
            left: 5px;
          }

          &.active {
            &::before {
              transform: rotate(-48deg);
              right: 3px;
              width: 9px;
            }

            &::after {
              width: 9px;
              transform: rotate(45deg);
              left: 2px;
            }
          }
        }

        .arrow {
          width: 4px;
          height: 4px;
          display: inline-block;
          position: relative;
          top: -10px;
          left: 5px;
        }

        .arrow span {
          top: 8px;
          position: absolute;
          width: 10px;
          height: 1.6px;
          background-color: black;
          display: inline-block;
          transition: all 0.2s ease;
        }

        .arrow span:first-of-type {
          left: 0;
          transform: rotate(45deg);
        }

        .arrow span:last-of-type {
          right: 0;
          transform: rotate(-45deg);
        }

        .arrow.active span:first-of-type {
          transform: rotate(-45deg);
        }

        .arrow.active span:last-of-type {
          transform: rotate(45deg);
        }

        .down {
          transform: rotate(0deg);
          transition: 0.3s;
          align-items: flex-end;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }

        .up {
          transform: rotate(-180deg);
          transition: 0.3s;
          align-items: flex-end;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }

        .filter-title-name {
          width: 71.5%;
        }

        &--active {
        }
      }
    }

    .profile {
      display: block;
      float: right;
      margin-top: 28px;
      height: 40px;

      .user {
        float: right;
        display: inline-block;
        width: 350px;
        height: 35px;

        @media (min-width: 1000px) and (max-width: 1250px) {
          width: 280px;
        }

        .user-name {
          float: right;
          font-size: 16px;
          font-weight: 500;
          text-align: right;
          padding-right: 10px;
          cursor: pointer;
          line-height: 35px;
          background-size: 12px 12px;
          display: flex;

          .first-name {
            font-weight: 500;
            margin-right: 12px;
            font-size: 16px;
            text-align: right;
            cursor: pointer;
            line-height: 35px;
          }

          .last-name {
            font-size: 16px;
            font-weight: 500;
            text-align: right;
            cursor: pointer;
            line-height: 35px;
            padding-right: 10px;
          }

          .wrap {
            &:hover {
              cursor: pointer;
            }

            .arrow {
              margin-left: 5px;
              top: 14px;
              left: 0;
              width: 20px;
              height: 20px;
            }

            .arrow span {
              top: 0;
              width: 10px;
              height: 1.8px;
            }

            .arrow span:first-of-type {
              left: -2px;
            }

            .arrow span:last-of-type {
              right: -2px;
            }
          }
        }

        .user-photo {
          float: right;
          overflow: hidden;
          margin-right: 12px;
          width: 35px;
          height: 35px;
          background-size: 40px 40px;
          box-sizing: border-box;
          border-radius: 100%;
          display: block;
          position: relative;
        }
      }


      .profile-menu {
        display: none;
        z-index: 100;
        position: absolute;
        right: 20px;
        top: 70px;
        padding: 12px 24px;
        background: white;
        border-radius: 12px;
        list-style: none;
      }

      .profile-menu-open {
        z-index: 100000;
        position: relative;
        background: white;
        border-radius: 12px;
        list-style: none;
        font-weight: lighter;

        .nav {
          list-style: none;

          .menu-list {
            list-style: none;
            padding: 0;

            .list-item-first {
              margin: 0;
              font-size: 14px;

              .item-link {
                padding: 20px 24px 0 24px;
                display: block;
                color: $paragraph-text-background;
                text-decoration: none;
              }


              .item-link:hover {
                text-decoration: underline;
              }
            }

            .list-item-last {
              margin: 0;
              font-size: 14px;

              .item-link {
                padding: 4px 24px 17px 24px;
                display: block;
                color: $paragraph-text-background;
                text-decoration: none;
              }

              .item-link:hover {
                text-decoration: underline;
              }
            }

            .list-item-last-log {
              &-block {
                display: flex;
              }

              &-img {
                width: 16px;
                margin: 7px -17px 0px 25px;
                height: 16px;
              }

              &-profile {
                width: 16px;
                margin: 18px -17px 0px 25px;
                height: 16px;
              }

              margin: 0;
              font-size: 14px;
              padding-top: 5px;

              .item-link {
                padding: 8px 24px 20px 24px;
                display: block;
                color: #fd510d;
                text-decoration: none;
              }

              .item-link:hover {
                text-decoration: underline;
              }
            }

            .none {
              display: none;
            }

            .list-item {
              padding-top: 5px;
              margin: 0;
              font-size: 14px;

              .item-link {
                padding: 9px 24px 0 24px;
                display: block;
                color: $paragraph-text-background;
                text-decoration: none;
              }

              .active {
                color: $btn-background;
                text-decoration: underline;
              }

              .item-link:hover {
                text-decoration: underline;
              }
            }

            .active {
              font-weight: bold;

              .item-link {
                color: $btn-background;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 0;
  }

  .admin {
    min-width: 800px;
    min-height: 200vh;
    position: absolute;
  }
}