@import "../../../assets/sass/var";

.add-client-window {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: $background-popup-window;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;

  &__wrap {
    border-radius: $border-radius-popup;
    transition: .5s all;
    position: relative;
    top: 0;
    left: 0;

    .btn-close-block {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;
    }

    &__alert {
      border-radius: $border-radius-popup;
      background: $main-background;
      min-width: 540px;
      width: 840px;
      max-width: 1270px;
      min-height: 50vh;
      max-height: 98vh;
      transition: .5s all;
      position: relative;
      top: 0;
      left: 0;
      overflow: scroll;

      .full-width {
        margin-left: 0 !important;
      }

      .add-client {
        .preloader {
          height: 100%;

          &-img {
            position: fixed;
            margin: auto;
            left: 80px;
            top: 100px;
            bottom: 0;
            right: 0;
            mix-blend-mode: multiply;
          }
        }

        &__form {
          @media (min-width: 1300px) {
            min-width: 480px;
            width: 780px;
            max-width: 1170px;
          }
          margin-bottom: 10px !important;

          .general-error {
            margin-top: -5px;
            margin-bottom: 5px;
          }
        }

        &__inputs-setting {
          justify-content: revert;
        }

        .branch-client-popup {
          display: none;
        }
      }


      .add-client__form {

        .blocked {
          margin-top: 20px;
          width: 30%;
        }
      }

      .add-client__title {
        font-size: 20px;
      }

      .btn-client-wrapp {
        position: relative;
        top: -60px;
        width: 390px;
        height: 60px;

        .client_submit {
          display: inline-block;
        }

        .btnd {
          display: inline-block;
        }
      }

    }
  }
}